import * as React from "react";
import { Moment } from "moment";
import DataTable, {TimePeriodTotals, TableValues} from "../../components/DataTable";
import * as ProductsApi from '../../modules/products/api';
import * as SalesApi from '../../modules/sales/api';
import * as DataTablesApi from "../../modules/datatables/api";
import { parseNumber, numberWithCommas, numberIfNaN } from "../../../misc/number_formatter";

const noProductsToDisplay = (
  <tr>
    <th className='text-center py-4 text-wrap'>
      <p>No products to display.</p>

      <p className='mb-0'>
        <a href='/products/new'>Click here</a> to create a new product.
      </p>
    </th>
  </tr>
);

const noMonthsToDisplay = () => (
  <tr>
    <th className='text-center py-4 text-wrap'>
      <p>No sales to display.</p>
    </th>
  </tr>
);

const tableHeader = (
  <div>
    <i className="fas fa-percent fa-fw text-muted mr-2"></i>
    Margin
  </div>
)

type Props= {
  onTableChange?: () => void,
}

export default function MarginDataTable({
  onTableChange
}: Props) {

  const [sales, setSales] = React.useState<TableValues>({} as TableValues);
  const [grossProfit, setGrossProfit] = React.useState<TableValues>({} as TableValues);

  const field = 'margin_percent';

  React.useEffect(() => {
    onDataTableChange()
  }, []);

  React.useEffect(() => {
    document.addEventListener('data_table:change', onDataTableChange);

    return () => {
      document.removeEventListener('data_table:change', onDataTableChange);
    }
  })

  const onDataTableChange = () => {
    Promise.all([ProductsApi.fetchProducts('sales', 'value', true), ProductsApi.fetchProducts('sales', 'gross_profit', true)])
      .then(([sales, grossProfit]) => {
        setSales(sales);
        setGrossProfit(grossProfit);
      })
  }

  const initializeTable = async () => {
    return await ProductsApi.fetchProducts('sales', field);
  }

  const commitCellUpdate = async (newValue: string, rowId: string, cellId: string) => {
    await SalesApi.updateSale(cellId, newValue, field);

    if (onTableChange)
      onTableChange();
  }

  const onCellCopyForward = async (newValue: string, rowId: string, cellId: string, month: Moment) => {
    await DataTablesApi.copyForward(rowId, 'Product', 'sales', newValue, month.toString(), field);

    if (onTableChange)
      onTableChange();
  }

  const yearTotalsEvaluator = (year: number, rowId?: string) => {
    let total = 0;

    if (sales.year_totals && grossProfit.year_totals) {
      const salesTotal = sales.year_totals[year];
      const grossProfitTotal = grossProfit.year_totals[year];

      if (salesTotal && grossProfitTotal) {
        if (rowId) {
          total = parseNumber(grossProfitTotal.values[rowId]) / parseNumber(salesTotal.values[rowId]);
        } else {
          total = parseNumber(grossProfitTotal.total) / parseNumber(salesTotal.total);
        }
      }
    }

    return numberWithCommas(numberIfNaN(total * 100, 0), 2);
  }

  const monthTotalsEvaluator = (month: Moment) => {
    let total = 0;

    if (sales.month_totals && grossProfit.month_totals) {
      const salesTotal = parseNumber(sales.month_totals[month.format('YYYY-MM-DD')]);
      const grossProfitTotal = parseNumber(grossProfit.month_totals[month.format('YYYY-MM-DD')]);

      if (salesTotal > 0 && grossProfitTotal)
        total = grossProfitTotal / salesTotal;
    }

    return numberWithCommas(numberIfNaN(total * 100, 0), 2);
  }

  return <DataTable
    initializeTable={initializeTable}
    columnsHeader='Product/Service'
    tableHeader={tableHeader}
    commitCellUpdate={commitCellUpdate}
    noRowsToDisplay={noProductsToDisplay}
    noMonthsToDisplay={noMonthsToDisplay}
    onCellCopyForward={onCellCopyForward}
    timePeriodTotals={TimePeriodTotals.Both}
    yearTotalsEvaluator={yearTotalsEvaluator}
    monthTotalsEvaluator={monthTotalsEvaluator}
    cellHint='%'
  />
}
