import * as Http from "../../utils/http";

export async function fetchTaxAssumptions() {
  const [json,] = await Http.get('/tax_assumptions.json');
  return json;
}

export async function updateTaxAssumption(id: string, newValue: string) {
  const [json,] = await Http.patch(`/tax_assumptions/${id}`, {
    tax_assumption: { value: newValue }
  });
  return json;
}

export async function updateTaxAssumptionThreshold(id: string, newThreshold: string) {
  const [json,] = await Http.patch(`/tax_assumptions/${id}`, {
    tax_assumption: { threshold: newThreshold }
  });
  return json;
}

export async function copyForwardThreshold(
    rowId: string,
    rowResourceType: string,
    childRelationType: string,
    threshold: string | number,
    month: string
) {
  const [json,] = await Http.post('/datatables/copy_forward', {
    resource: rowResourceType,
    resource_id: rowId,
    association: childRelationType,
    from_month: month,
    threshold,
  });
  return json;
}
