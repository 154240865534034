import * as React from "react";
import { Moment } from "moment";
import DataTable, {TimePeriodTotals} from "../components/DataTable";
import { Product } from "../modules/products/types"
import * as ProductsApi from '../modules/products/api';
import * as SalesApi from '../modules/sales/api';
import * as DataTablesApi from "../modules/datatables/api";

// technically impossible
const noRowsToDisplay = <React.Fragment></React.Fragment>;

const tableHeader = (
  <div>
    <i className="fas fa-calendar-alt fa-fw text-muted mr-2"></i>
    Supplier days to pay
  </div>
)

type Props= {
  product: Product,
}

export default function SupplierDaysToPayDataTable({
  product,
}: Props) {

  const field = 'supplier_days_to_pay'

  const initializeTable = async () => {
    return await ProductsApi.fetchProduct(product.id, 'sales', field);
  }

  const commitCellUpdate = async (newValue: string, rowId: string, cellId: string) => {
    await SalesApi.updateSale(cellId, newValue, field);
  }

  const onCellCopyForward = async (newValue: string, rowId: string, cellId: string, month: Moment) => {
    await DataTablesApi.copyForward(rowId, 'Product', 'sales', newValue, month.toString(), field);
  }

  return <DataTable
    initializeTable={initializeTable}
    columnsHeader='Days to pay'
    tableHeader={tableHeader}
    commitCellUpdate={commitCellUpdate}
    noRowsToDisplay={noRowsToDisplay}
    onCellCopyForward={onCellCopyForward}
    timePeriodTotals={TimePeriodTotals.None}
  />
}
