import * as Http from "../../utils/http";

export async function fetchEquity() {
  const [json,] = await Http.get('/equity.json');
  return json;
}

export async function updateEquity(id: string, newValue: string) {
  const [json,] = await Http.patch(`/equity/${id}`, {
    equity: { value: newValue }
  });
  return json;
}
