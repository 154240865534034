import * as React from 'react'
import { CurrencyInput } from '../../components/CurrencyInput'
import FixedAssetCategorySelect from './FixedAssetCategorySelect'
import { FixedAsset } from '../../modules/opening-balance-sheet/types'
import { currencyToNumber } from '../../../misc/currency'

interface Errors {
  [key: string]: string | undefined
}

function FixedAssetRow(
  props: {fixedAsset: FixedAsset, handleRemoveRow: any, setParent: Function}
) {
  const [fixedAsset, setFixedAsset] = React.useState(props.fixedAsset)
  const [errors, setErrors] = React.useState<Errors>({})

  const validateFixedAssetValues = (asset: FixedAsset) => {
    if (currencyToNumber(asset.originalValue) < currencyToNumber(asset.bookValue)) {
      setErrors({ ...errors, originalValue: "Original value must be greater than or equal to the book value." })
    } else {
      setErrors({ ...errors, originalValue: undefined })
    }
  }

  const updateField = (field: string) => (e: any) => {
    const value = e.target.value
    updateFieldValue(field)(value)
  }

  const updateFieldValue = (field: string) => (value: any) => {
    const clone = {...fixedAsset}
    clone[field] = value
    setFixedAsset(clone)
    props.setParent(field, value)

    validateFixedAssetValues(clone)
  }

  return (
    <>
      <tr id={fixedAsset.id}>
        <td className='align-middle'>
          <FixedAssetCategorySelect
            value={fixedAsset.category}
            setHook={updateFieldValue('category')}
          />
        </td>
        <td>
          <CurrencyInput
            value={fixedAsset.originalValue}
            setHook={updateFieldValue('originalValue')}
            className={errors.originalValue && 'is-invalid'}
          />
        </td>
        <td>
          <CurrencyInput
            value={fixedAsset.bookValue}
            setHook={updateFieldValue('bookValue')}
          />
        </td>
        <td className='align-middle'>
          <a
            className='btn btn-sm btn-light border py-2'
            onClick={props.handleRemoveRow}
          >
            <i className='fas fa-fw fa-trash'></i>
          </a>
        </td>
      </tr>
      {errors.originalValue && (
        <tr className="table-borderless">
          <td />
          <td colSpan={3} className="pt-0">
            <div className="small text-danger">{errors.originalValue}</div>
          </td>
        </tr>
      )}
    </>
  )
}

export default FixedAssetRow
