import * as React from "react";
import moment, {Moment} from "moment";
import * as TaxAssumptionsApi from "../modules/tax-assumptions/api";
import * as DataTablesApi from "../modules/datatables/api";
import Cell from "../components/data-table/Cell";
import {monthRange} from "../utils/dates";
import TaxAssumptionsHeaderRow from "../components/data-table/TaxAssumptionsHeaderRow";
import {TimePeriodTotals} from "../components/DataTable";

export type CellValueType = string;

export type ColumnData = {
    month: Moment,
    threshold: CellValueType,
    value: CellValueType,
    cellHint?: string
}

export type CellsCollection = {
    [cellId: string]: ColumnData
}

export type RowData = {
    name: string,
    cells: CellsCollection
}

export type DataRowsCollection = {
    [parentId: string]: RowData
}

type TableValues = {
    start_month: Moment,
    end_month: Moment,
    collection: DataRowsCollection,
    month_totals: MonthTotals,
    year_totals: YearTotals
}

export type MonthTotals = {
    [month: string]: number
}

export type ValueYearTotal = {
    [month: string]: number
}

export type YearTotal = {
    start: Moment,
    end: Moment,
    values: ValueYearTotal,
    total: number
}

export type YearTotals = {
    [yearNo: number]: YearTotal
}

type Props = {
    cellHint?: string,
    columnsHeader: string,
    tableHeader: React.ReactElement,
    noRowsToDisplay: React.ReactElement,
    noMonthsToDisplay: () => React.ReactElement,
    initializeTable: () => Promise<TableValues>,
    onCellUpdate?: (newValue: CellValueType, parentId: string, childId: string) => void,
    onCellCopyForward: (newValue: CellValueType, parentId: string, childId: string, month: Moment) => void,
    onCellCopyForwardThreshold: (newThreshold: CellValueType, parentId: string, childId: string, month: Moment) => void,
    commitCellUpdate: (value: CellValueType, parentId: string, childId: string) => Promise<any>,
    commitCellThresholdUpdate: (value: CellValueType, parentId: string, childId: string) => Promise<any>,
    timePeriodTotals: TimePeriodTotals,
}

function DataTable({
                       cellHint,
                       columnsHeader,
                       tableHeader,
                       noRowsToDisplay,
                       noMonthsToDisplay,
                       initializeTable,
                       commitCellUpdate,
                       onCellUpdate,
                       onCellCopyForward,
                       timePeriodTotals,
                       commitCellThresholdUpdate,
                       onCellCopyForwardThreshold
                   }: Props) {

    const [collection, setCollection] = React.useState({} as DataRowsCollection);
    const [startMonth, setStartMonth] = React.useState(moment('2021-01-01'));
    const [endMonth, setEndMonth] = React.useState(moment('2021-12-01'));
    const [isLoading, setIsLoading] = React.useState(false);
    const [monthTotals, setMonthTotals] = React.useState({} as MonthTotals);
    const [yearTotals, setYearTotals] = React.useState({} as YearTotals);

    const monthsForYear = monthRange(startMonth, endMonth);

    React.useEffect(() => {
        setIsLoading(true);

        initializeTable().then(({start_month, end_month, collection, month_totals, year_totals}) => {
            setMonthTotals(month_totals);
            setYearTotals(year_totals);
            setCollection(collection);
            setStartMonth(moment(start_month));
            setEndMonth(moment(end_month));

            setIsLoading(false);
        });
    }, []);

    // handlers

    const handleCellValueUpdate = (rowId: string, cellId: string) => (newValue: string) => {
        const collectionCopy = {...collection};
        collectionCopy[rowId].cells[cellId].value = newValue;
        setCollection(collectionCopy);

        if (onCellUpdate)
            onCellUpdate(newValue, rowId, cellId);
    }

    const onCellValueBlur = (rowId: string, cellId: string) => () => {
        setIsLoading(true);
        commitCellUpdate(collection[rowId].cells[cellId].value, rowId, cellId)
            .then(initializeTable)
            .then(({month_totals, year_totals}) => {
                setYearTotals(year_totals);
                setMonthTotals(month_totals);
                setIsLoading(false);
            });
    }

    const handleCellThresholdUpdate = (rowId: string, cellId: string) => (newThreshold: string) => {
        const collectionCopy = {...collection};
        collectionCopy[rowId].cells[cellId].threshold = newThreshold;
        setCollection(collectionCopy);

        if (onCellUpdate)
            onCellUpdate(newThreshold, rowId, cellId);
    }

    const onCellThresholdBlur = (rowId: string, cellId: string) => () => {
        setIsLoading(true);
        commitCellThresholdUpdate(collection[rowId].cells[cellId].threshold, rowId, cellId)
            .then(initializeTable)
            .then(({month_totals, year_totals}) => {
                setYearTotals(year_totals);
                setMonthTotals(month_totals);
                setIsLoading(false);
            });
    }

    const handleCopyForward = (rowId: string, cellId: string) => () => {
        const cellValue = collection[rowId].cells[cellId].value;
        const row = {...collection[rowId]};
        const {month, value} = row.cells[cellId];

        row.cells = Object.entries(row.cells).reduce((cells, [id, cell]) => ({
            ...cells,
            [id]: {...cell, value: cell.month > month ? cellValue : cell.value}
        }), {})

        setCollection({...collection, [rowId]: row});

        onCellCopyForward(value, rowId, cellId, month);
    }

    const handleCopyForwardThreshold = (rowId: string, cellId: string) => () => {
        const cellValue = collection[rowId].cells[cellId].threshold;
        const row = {...collection[rowId]};
        const {month, threshold} = row.cells[cellId];

        row.cells = Object.entries(row.cells).reduce((cells, [id, cell]) => ({
            ...cells,
            [id]: {...cell, threshold: cell.month > month ? cellValue : cell.threshold}
        }), {})

        setCollection({...collection, [rowId]: row});

        onCellCopyForwardThreshold(threshold, rowId, cellId, month);
    }

    // presentation

    const renderTableRow = ([rowId, {name, cells}]: [string, RowData]) => {
        return (
            <tr key={rowId}>
                <th className="align-middle">{name}</th>

                {
                    Object.entries(cells).map(([cellId, {month, threshold, value}], index) => {
                        const isNewYear = index !== 0 && index % 12 === 0;
                        return (<React.Fragment key={index}>
                            <Cell
                                className={isNewYear ? 'yearDivider' : ''}
                                value={threshold}
                                onChange={handleCellThresholdUpdate(rowId, cellId)}
                                onBlur={onCellThresholdBlur(rowId, cellId)}
                                cellHint='£'
                                onCopyForward={handleCopyForwardThreshold(rowId, cellId)}
                            />
                            <Cell
                                value={value}
                                onChange={handleCellValueUpdate(rowId, cellId)}
                                onBlur={onCellValueBlur(rowId, cellId)}
                                cellHint='%'
                                onCopyForward={handleCopyForward(rowId, cellId)}
                            />
                        </React.Fragment>);
                    })
                }
            </tr>
        );
    }

    const renderTable = () => {
        if (Object.values(collection).length === 0)
            return noRowsToDisplay;

        else if (monthsForYear.length === 0 || Object.values(Object.values(collection)[0].cells).length === 0)
            return noMonthsToDisplay();

        else
            return (<React.Fragment>{Object.entries(collection).map(renderTableRow)}</React.Fragment>)
    }

    // view

    return (
        <div className="card">
            <div className="card-header d-flex justify-content-between">
                <div className="align-self-center">
                    {tableHeader}
                </div>

            </div>

            <div className="table-scroll data-table">
                <table className="table table-bordered">
                    <thead>
                    <TaxAssumptionsHeaderRow monthsForYear={monthsForYear} type={columnsHeader}
                                             timePeriodTotals={timePeriodTotals}/>
                    </thead>
                    <tbody>
                    {renderTable()}
                    </tbody>
                </table>
            </div>

            {isLoading &&
            <div className="data-table-loading">
                <div className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
            }
        </div>
    )
}


// technically impossible
const noRowsToDisplay = (
    <tr>
        <th className='text-center py-4 text-wrap'>
            <p>No tax types to display.</p>

            <p className='mb-0'>
                <a href='/tax_types/new'>Click here</a> to create a new tax type.
            </p>
        </th>
    </tr>
);

const noMonthsToDisplay = () => (
    <tr>
        <th className='text-center py-4 text-wrap'>
            <p>No tax assumptions to display.</p>
        </th>
    </tr>
);

const tableHeader = (
    <div>
        <i className="fas fa-percent fa-fw text-muted mr-2"></i>
        Tax Assumptions
    </div>
)

export default function TaxAssumptionsDataTable() {
    const initializeTable = async () => {
        return await TaxAssumptionsApi.fetchTaxAssumptions();
    }

    const commitCellUpdate = async (newValue: string, rowId: string, cellId: string) => {
        await TaxAssumptionsApi.updateTaxAssumption(cellId, newValue);
    }

    const commitCellThresholdUpdate = async (newThreshold: string, rowId: string, cellId: string) => {
        await TaxAssumptionsApi.updateTaxAssumptionThreshold(cellId, newThreshold);
    }

    const onCellCopyForward = async (newValue: string, rowId: string, cellId: string, month: Moment) => {
        await DataTablesApi.copyForward(rowId, 'TaxType', 'tax_assumptions', newValue, month.toString());
    }

    const onCellCopyForwardThreshold = async (newThreshold: string, rowId: string, cellId: string, month: Moment) => {
        await TaxAssumptionsApi.copyForwardThreshold(rowId, 'TaxType', 'tax_assumptions', newThreshold, month.toString());
    }
    return <DataTable
        initializeTable={initializeTable}
        columnsHeader='Tax'
        tableHeader={tableHeader}
        commitCellUpdate={commitCellUpdate}
        commitCellThresholdUpdate={commitCellThresholdUpdate}
        noRowsToDisplay={noRowsToDisplay}
        noMonthsToDisplay={noMonthsToDisplay}
        onCellCopyForward={onCellCopyForward}
        onCellCopyForwardThreshold={onCellCopyForwardThreshold}
        timePeriodTotals={TimePeriodTotals.None}
    />
}


