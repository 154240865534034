import * as React from 'react'
import { CurrencyInputWithLabel } from '../CurrencyInput'
import { AdditionalFields } from '../../modules/opening-balance-sheet/types'

function AdditionalFieldsCard(
  props: {additionalFields: AdditionalFields, setParent: Function}
) {
  const [additionalFields, setAdditionalFields] = React.useState(props.additionalFields)

  const updateField = (field: string) => (value: any) => {
    const clone = {...additionalFields}
    clone[field] = value
    setAdditionalFields(clone)
    props.setParent(clone)
  }

  return <>
    <div className='card mb-4 font-weight-bold'>
      <div className='card-body'>
        <CurrencyInputWithLabel
          label='Net Assets'
          value={additionalFields.netAssets}
          setHook={updateField('netAssets')}
          disabled={true}
        />
      </div>
    </div>
    <div className='card mb-4 font-weight-bold'>
      <div className='card-body'>
        <CurrencyInputWithLabel
          label='Share Capital'
          value={additionalFields.shareCapital}
          setHook={updateField('shareCapital')}
        />
        <CurrencyInputWithLabel
          label='Reserves'
          value={additionalFields.reserves}
          setHook={updateField('reserves')}
        />
      </div>
    </div>
  </>
}

export default AdditionalFieldsCard
