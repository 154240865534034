import * as React from "react";
import { Moment } from "moment";
import * as EquityApi from "../modules/equity/api";
import * as DataTablesApi from "../modules/datatables/api";
import DataTable, {TimePeriodTotals} from "../components/DataTable";

// technically impossible
const noRowsToDisplay = <React.Fragment></React.Fragment>;

const tableHeader = (
  <div>
    <i className="fas fa-chart-pie fa-fw text-muted mr-2"></i>
    Equity funds received
  </div>
)

export default function EquityDataTable() {
  const initializeTable = async () => {
    return await EquityApi.fetchEquity();
  }

  const commitCellUpdate = async (newValue: string, rowId: string, cellId: string) => {
    await EquityApi.updateEquity(cellId, newValue);
  }

  const onCellCopyForward = async (
    newValue: string,
    rowId: string,
    cellId: string,
    month: Moment,
    resourceType?: string,
    resourceId?: string,
    toMonth?: Moment
  ) => {
    if (resourceId !== undefined && toMonth !== undefined)
      await DataTablesApi.copyForward(resourceId, 'Company', 'equity', newValue, month.toString(), 'value', toMonth.toString());
  }

  return <DataTable
    initializeTable={initializeTable}
    columnsHeader=''
    tableHeader={tableHeader}
    commitCellUpdate={commitCellUpdate}
    onCellCopyForward={onCellCopyForward}
    noRowsToDisplay={noRowsToDisplay}
    cellHint='£'
    timePeriodTotals={TimePeriodTotals.Yearly}
    headerMonthFormat='MMM'
  />
}
