import * as Http from "../../utils/http";

export async function fetchOpeningBalanceSheet() {
  const [json,] = await Http.get(`/opening_balance_sheet.json`);
  return json;
}

export async function updateOpeningBalanceSheet(data: object) {
  return await Http.patch(`/opening_balance_sheet`, { opening_balance_sheet: data });
}
