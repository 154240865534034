import * as React from 'react'
import DebtorRow from './DebtorRow'
import { Debtor, emptyDebtor } from '../../modules/opening-balance-sheet/types'

interface Props {
  debtors: Debtor[]
  setParent: Function
}

function DebtorsTable({debtors, setParent}: Props) {
  const [propDebtors, setDebtors] = React.useState(debtors)

  const addDebtor = () => {
    const newDebtors = propDebtors.concat(emptyDebtor())
    setDebtors(newDebtors)
    setParent(newDebtors)
  }

  const removeDebtor = (id: string) => () => {
    updateDebtors(id)('_destroy', true)
  }

  const updateDebtors = (id: string) => (field: string, value: any) => {
    const clone = [...propDebtors]
    for (let i in clone) {
      if (clone[i].id == id) {
        clone[i][field] = value
        break
      }
    }
    setDebtors(clone)
    setParent(clone)
  }

  const activeDebtors = () => propDebtors.filter(debtor => !debtor._destroy)

  return <>
    <table className='table table-borderless table-sm'>
      <tbody>
        {
          activeDebtors().map(debtor => {
            return <DebtorRow
              key={debtor.id}
              debtor={debtor}
              handleRemoveRow={removeDebtor(debtor.id)}
              setParent={updateDebtors(debtor.id)}
            />
          })
        }
      </tbody>
      <tfoot>
        <tr>
          <td className='w32p text-right'>
            <a
              className='btn btn-light border'
              onClick={addDebtor}
            >
              <i className='fas fa-plus mr-2'></i>
              Add Debtor
            </a>
          </td>
          <td className='w21p' />
          <td />
        </tr>
      </tfoot>
    </table>
  </>
}

export default DebtorsTable
