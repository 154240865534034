import * as React from "react";
import { Moment } from "moment";
import DataTable, {TimePeriodTotals} from "../components/DataTable";
import * as SalariesApi from '../modules/salaries/api';
import * as DataTablesApi from "../modules/datatables/api";

const noSalariesToDisplay = (
  <tr>
    <th className='text-center py-4 text-wrap'>
      <p>No salaries to display.</p>

      <p className='mb-0'>
        <a href='/salaries/new'>Click here</a> to create a new salary.
      </p>
    </th>
  </tr>
);

const noMonthsToDisplay = () => (
  <tr>
    <th className='text-center py-4 text-wrap'>
      <p>No months to display.</p>
    </th>
  </tr>
);

const tableHeader = (title: string) => (
  <div>
    <i className="fas fa-hand-holding-usd fa-fw text-muted mr-2"></i>
    {title}
  </div>
)

type Props = {
  scope: SalariesApi.SalariesScope,
  title: string,
}

export default function SalariesDataTable({
  scope,
  title
}: Props) {

  const initializeTable = async () => {
    return await SalariesApi.fetchSalaries({ scope: scope });
  }

  const commitCellUpdate = async (newValue: string, rowId: string, cellId: string) => {
    await SalariesApi.updateSalaryMonth(cellId, newValue);
  }

  const onCellCopyForward = async (newValue: string, rowId: string, cellId: string, month: Moment) => {
    await DataTablesApi.copyForward(rowId, 'Salary', 'salary_months', newValue, month.toString());
  }

  return <DataTable
    initializeTable={initializeTable}
    columnsHeader='Staff'
    tableHeader={tableHeader(title)}
    commitCellUpdate={commitCellUpdate}
    noRowsToDisplay={noSalariesToDisplay}
    noMonthsToDisplay={noMonthsToDisplay}
    cellHint='£'
    onCellCopyForward={onCellCopyForward}
    timePeriodTotals={TimePeriodTotals.Both}
  />
}


