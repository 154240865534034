import * as Http from "../../utils/http";

export enum OverheadsScope {
  All = 'all',
  Overheads = 'overheads',
  CostOfSales = 'cost_of_sales',
}

interface FetchOverheadsParams {
  scope: OverheadsScope
}

export async function fetchOverheads({ scope }: FetchOverheadsParams) {
  const [json,] = await Http.get(`/overheads.json?scope=${scope}`);
  return json;
}

export async function fetchOverhead(id: string, valueField: string = 'value') {
  const [json,] = await Http.get(`/overheads/${id}.json?value_field=${valueField}`);
  return json;
}

export async function updateOverheadMonth(id: string, newValue: string, field: string = 'value') {
  const [json,] = await Http.patch(`/overhead_monthly_costs/${id}`, {
    overhead_monthly_cost: { [field]: newValue }
  });
  return json;
}
