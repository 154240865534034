import * as Http from "../../utils/http";

export async function fetchProducts(cellType: string = 'all', valueField: string = 'value', includeTotals: boolean = false) {
  const [json,] = await Http.get(`/products.json?field=${cellType}&value_field=${valueField}&include_totals=${includeTotals}`);
  return json;
}

export async function fetchProduct(id: string, cellType: string = 'all', valueField: string = 'value') {
  const [json,] = await Http.get(`/products/${id}.json?field=${cellType}&value_field=${valueField}`);
  return json;
}
