import * as Http from "../../utils/http";

export enum OtherDebtorsScope {
  All = 'all',
  Opening = 'opening',
  New = 'new',
}

interface FetchDebtorsParams {
  scope: OtherDebtorsScope
}

export async function fetchDebtors({ scope }: FetchDebtorsParams) {
  const [json,] = await Http.get(`/other_debtors.json?scope=${scope}`);
  return json;
}

export async function updateDebtorMonth(id: string, newValue: string, field: string = 'value') {
  const [json,] = await Http.patch(`/debtor_monthly_amounts/${id}`, {
    debtor_monthly_amount: { [field]: newValue }
  });
  return json;
}
