import * as React from 'react'
import { CurrencyInput } from '../../components/CurrencyInput'
import { Debtor } from '../../modules/opening-balance-sheet/types'

function DebtorRow(
  props: {debtor: Debtor, handleRemoveRow: any, setParent: Function}
) {
  const [debtor, setDebtor] = React.useState(props.debtor)

  const updateField = (field: string) => (e: any) => {
    const value = e.target.value
    updateFieldValue(field)(value)
  }

  const updateFieldValue = (field: string) => (value: any) => {
    const clone = {...debtor}
    clone[field] = value
    setDebtor(clone)
    props.setParent(field, value)
  }

  return (
    <tr id={debtor.id}>
      <td className='pl-0'>
        <input
          value={debtor.name}
          onChange={updateField('name')}
          className='form-control string'
          placeholder='Name'
        />
      </td>
      <td>
        <CurrencyInput
          value={debtor.amount}
          setHook={updateFieldValue('amount')}
          placeholder='Amount'
        />
      </td>
      <td className='align-middle pr-0'>
        <a
          className='btn btn-sm btn-light border py-2'
          onClick={props.handleRemoveRow}
        >
          <i className='fas fa-fw fa-trash'></i>
        </a>
      </td>
    </tr>
  )
}

export default DebtorRow
