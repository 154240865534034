import * as React from 'react'
import ExistingLoanRow from './ExistingLoanRow'
import { ExistingLoan, emptyExistingLoan } from '../../modules/opening-balance-sheet/types'

interface Props {
  existingLoans: ExistingLoan[]
  setParent: Function
}

function ExistingLoansCard({existingLoans, setParent}: Props) {
  const [propExistingLoans, setExistingLoans] = React.useState(existingLoans)

  const addExistingLoan = () => {
    const newExistingLoans = propExistingLoans.concat(emptyExistingLoan())
    setExistingLoans(newExistingLoans)
    setParent(newExistingLoans)
  }

  const removeExistingLoan = (id: string) => () => {
    updateExistingLoans(id)('_destroy', true)
  }

  const updateExistingLoans = (id: string) => (field: string, value: any) => {
    const clone = [...propExistingLoans]
    for (let i in clone) {
      if (clone[i].id == id) {
        clone[i][field] = value
        break
      }
    }
    setParent(clone)
  }

  const activeExistingLoans = () => propExistingLoans.filter(loan => !loan._destroy)

  const tableHead = () => {
    return (
      <thead>
        <tr>
          <th className='w32p'>Name</th>
          <th className='w21p'>Amount Outstanding</th>
          <th className='w21p'>Monthly Fixed Payment</th>
          <th className='w21p'>Months Remaining</th>
          <th className='w5p'></th>
        </tr>
      </thead>
    )
  }

  return (
    <table className='table table-borderless table-sm'>
      {activeExistingLoans().length > 0 && tableHead()}
      <tbody>
        {
          activeExistingLoans().map(existingLoan => {
            return <ExistingLoanRow
              key={existingLoan.id}
              existingLoan={existingLoan}
              handleRemoveRow={removeExistingLoan(existingLoan.id)}
              setParent={updateExistingLoans(existingLoan.id)}
            />
          })
        }
      </tbody>
      <tfoot>
        <tr>
          <td className='w32p text-right'>
            <a
              className='btn btn-light border'
              onClick={addExistingLoan}
            >
              <i className='fas fa-plus mr-2'></i>
              Add Existing Loan
            </a>
          </td>
          <td colSpan={4} />
        </tr>
      </tfoot>
    </table>
  )
}

export default ExistingLoansCard
