import * as React from "react";
import { Moment } from "moment";
import DataTable, {TimePeriodTotals} from "../components/DataTable";
import * as ProductsApi from '../modules/products/api';
import * as DataTablesApi from "../modules/datatables/api";
import * as PaymentTermsApi from '../modules/payment-terms/api';

const noRowsToDisplay = (
  <tr>
    <th className='text-center py-4 text-wrap'>
      <p>No products to display.</p>

      <p className='mb-0'>
        <a href='/products/new'>Click here</a> to create a new product.
      </p>
    </th>
  </tr>
);

const noMonthsToDisplay = () => (
  <tr>
    <th className='text-center py-4 text-wrap'>
      <p>No customer payment terms to display.</p>
    </th>
  </tr>
);

const tableHeader = (
  <div>
    <i className="fas fa-calendar-alt fa-fw text-muted mr-2"></i>
    Customer Payment Terms
  </div>
)

export default function PaymentTermsDataTable() {
  const initializeTable = async () => {
    return await ProductsApi.fetchProducts('payment_terms');
  }

  const commitCellUpdate = async (newValue: string, rowId: string, cellId: string) => {
    await PaymentTermsApi.updatePaymentTerm(cellId, newValue);
  }

  const onCellCopyForward = async (newValue: string, rowId: string, cellId: string, month: Moment) => {
    await DataTablesApi.copyForward(rowId, 'Product', 'payment_terms', newValue, month.toString());
  }

  return <DataTable
    initializeTable={initializeTable}
    columnsHeader='Product/Services'
    tableHeader={tableHeader}
    commitCellUpdate={commitCellUpdate}
    noRowsToDisplay={noRowsToDisplay}
    noMonthsToDisplay={noMonthsToDisplay}
    onCellCopyForward={onCellCopyForward}
    timePeriodTotals={TimePeriodTotals.None}
  />
}


