import * as React from 'react'
import FixedAssetRow from './FixedAssetRow'
import { FixedAsset, emptyFixedAsset } from '../../modules/opening-balance-sheet/types'

interface Props {
  fixedAssets: FixedAsset[]
  setParent: Function
}

function FixedAssetsCard({fixedAssets, setParent}: Props) {
  const [propFixedAssets, setFixedAssets] = React.useState(fixedAssets)

  const addFixedAsset = () => {
    const newFixedAssets = propFixedAssets.concat(emptyFixedAsset())
    setFixedAssets(newFixedAssets)
    setParent(newFixedAssets)
  }

  const removeFixedAsset = (id: string) => () => {
    updateFixedAssets(id)('_destroy', true)
  }

  const updateFixedAssets = (id: string) => (field: string, value: any) => {
    const clone = [...propFixedAssets]
    for (let i in clone) {
      if (clone[i].id == id) {
        clone[i][field] = value
        break
      }
    }
    setParent(clone)
  }

  return (
    <div className='card mb-4'>
      <div className='card-header'>
        Fixed Assets
      </div>
      <div className='card-body'>
        <div className='card-body bg-light rounded border mb-4'>
          <a href='/fixed_asset_categories'>Manage Categories</a>
        </div>
        <table className='table padded no-top-border'>
          <thead>
            <tr>
              <th className='w35p'>Category</th>
              <th className='w30p'>Original Value</th>
              <th className='w30p'>Book Value</th>
              <th className='w5p'></th>
            </tr>
          </thead>
          <tbody>
            {
              propFixedAssets.filter(fixedAsset => !fixedAsset._destroy).map((fixedAsset, index) => {
                return <FixedAssetRow
                  key={fixedAsset.id}
                  fixedAsset={fixedAsset}
                  handleRemoveRow={removeFixedAsset(fixedAsset.id)}
                  setParent={updateFixedAssets(fixedAsset.id)}
                />
              })
            }
          </tbody>
        </table>
        <div className='text-right'>
          <a
            className='btn btn-light border'
            onClick={addFixedAsset}
          >
            <i className='fas fa-plus mr-2'></i>
            Add Fixed Asset
          </a>
        </div>
      </div>
    </div>
  )
}

export default FixedAssetsCard
