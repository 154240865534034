import * as React from 'react'
import CreditorRow from './CreditorRow'
import { Creditor, emptyCreditor } from '../../modules/opening-balance-sheet/types'

interface Props {
  creditors: Creditor[]
  setParent: Function
}

function CreditorsTable({creditors, setParent}: Props) {
  const [propCreditors, setCreditors] = React.useState(creditors)

  const addCreditor = () => {
    const newCreditors = propCreditors.concat(emptyCreditor())
    setCreditors(newCreditors)
    setParent(newCreditors)
  }

  const removeCreditor = (id: string) => () => {
    updateCreditors(id)('_destroy', true)
  }

  const updateCreditors = (id: string) => (field: string, value: any) => {
    const clone = [...propCreditors]
    for (let i in clone) {
      if (clone[i].id == id) {
        clone[i][field] = value
        break
      }
    }
    setCreditors(clone)
    setParent(clone)
  }

  const activeCreditors = () => propCreditors.filter(creditor => !creditor._destroy)

  return <>
    <table className='table table-borderless table-sm'>
      <tbody>
        {
          activeCreditors().map(creditor => {
            return <CreditorRow
              key={creditor.id}
              creditor={creditor}
              handleRemoveRow={removeCreditor(creditor.id)}
              setParent={updateCreditors(creditor.id)}
            />
          })
        }
      </tbody>
      <tfoot>
        <tr>
          <td className='w32p text-right'>
            <a
              className='btn btn-light border'
              onClick={addCreditor}
            >
              <i className='fas fa-plus mr-2'></i>
              Add Creditor
            </a>
          </td>
          <td className='w21p' />
          <td />
        </tr>
      </tfoot>
    </table>
  </>
}

export default CreditorsTable
