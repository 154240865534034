import * as React from 'react'
import { CurrencyInput } from '../../components/CurrencyInput'
import { ExistingLoan } from '../../modules/opening-balance-sheet/types'

function ExistingLoanRow(
  props: {existingLoan: ExistingLoan, handleRemoveRow: any, setParent: Function}
) {
  const [existingLoan, setExistingLoan] = React.useState(props.existingLoan)

  const updateField = (field: string) => (e: any) => {
    const value = e.target.value
    updateFieldValue(field)(value)
  }

  const updateFieldValue = (field: string) => (value: any) => {
    const clone = {...existingLoan}
    clone[field] = value
    setExistingLoan(clone)
    props.setParent(field, value)
  }

  return (
    <tr id={existingLoan.id}>
      <td className='pl-0'>
        <input
          value={existingLoan.name}
          onChange={updateField('name')}
          className='form-control string'
        />
      </td>
      <td>
        <CurrencyInput
          value={existingLoan.amountOutstanding}
          setHook={updateFieldValue('amountOutstanding')}
        />
      </td>
      <td>
        <CurrencyInput
          value={existingLoan.monthlyFixedPayment}
          setHook={updateFieldValue('monthlyFixedPayment')}
        />
      </td>
      <td>
        <input
          type='number'
          value={existingLoan.noOfMonthsRemaining}
          onChange={updateField('noOfMonthsRemaining')}
          className='form-control string'
          min={1}
        />
      </td>
      <td className='align-middle pr-0'>
        <a
          className='btn btn-sm btn-light border py-2'
          onClick={props.handleRemoveRow}
        >
          <i className='fas fa-fw fa-trash'></i>
        </a>
      </td>
    </tr>
  )
}

export default ExistingLoanRow
