import * as Csrf from './csrf';

export const get = defineRequest('GET')
export const patch = defineRequest('PATCH')
export const put = defineRequest('PUT')
export const head = defineRequest('HEAD')
export const post = defineRequest('POST')
export const del = defineRequest('DELETE')

export const formatErrors = (errors: { [field: string]: string[] }) => {
  const parts: string[] = [];

  for (const [fieldName, errorMessages] of Object.entries(errors)) {
    const field = fieldName.replace(/_/g, ' ');
    const uniqueErrors = Array.from(new Set(errorMessages));

    parts.push(`${field}: ${uniqueErrors.join(', ')}`)
  }

  return parts.join('\n');
}

export const formatErrorsOrMessage = ({ message, errors }: MessageOrErrors, fallbackMessage: string = 'Failure') => {
  if (message !== undefined)
    return message

  else if (errors !== undefined)
    return formatErrors(errors)

  else
    return fallbackMessage
}

// Private

const jsonRequest = async ({ url, method, body, headers }: Request) => {
  const jsonHeaders = {
    ...headers,
    "Content-Type": "application/json",
    "Accept": "application/json",
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': Csrf.getToken()
  }

  try {
    const response = await fetch(url, { method, body: JSON.stringify(body), headers: jsonHeaders })
    const json = response.status !== 204 ? await response.json() : null

    return Promise.resolve([json, response])
  } catch (error) {
    return Promise.reject(error)
  }
}

function defineRequest(method: string) {
  return async (url: string, bodyOrHeaders?: any, headers?: any) => {

    let params: Request = { url, method }

    // Flexible params (GET and HEAD have no body)
    if (method === 'GET' || method === 'HEAD')
      params = { ...params, headers: bodyOrHeaders }
    else
      params = { ...params, body: bodyOrHeaders, headers }

    const [json, response] = await jsonRequest(params)

    return Promise.resolve([json, response])
  }
}

// Types

type Request = {
  url: string,
  method: string,
  body?: any,
  headers?: any
}

type Errors = { [field: string]: string[] }
type MessageOrErrors = { message?: string, errors?: Errors }