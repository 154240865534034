import * as React from "react";
import {Moment} from "moment";
import DataTable, {TimePeriodTotals} from "../components/DataTable";
import * as DividendsApi from "../modules/dividends/api";
import * as DataTablesApi from "../modules/datatables/api";

// technically impossible
const noRowsToDisplay = <React.Fragment></React.Fragment>;

const noMonthsToDisplay = () => (
  <tr>
    <th className='text-center py-4 text-wrap'>
      <p>No dividends to display.</p>
    </th>
  </tr>
);

const tableHeader = (
  <div>
    <i className="fas fa-coins fa-fw text-muted mr-2"></i>
    Dividends
  </div>
)

export default function DividendsDataTable() {
  const initializeTable = async () => {
    return await DividendsApi.fetchDividends();
  }

  const commitCellUpdate = async (newValue: string, rowId: string, cellId: string) => {
    await DividendsApi.updateDividend(cellId, newValue);
  }

  const onCellCopyForward = async (
    newValue: string,
    rowId: string,
    cellId: string,
    month: Moment,
    resourceType?: string,
    resourceId?: string,
    toMonth?: Moment
  ) => {
    if (resourceId !== undefined && toMonth !== undefined)
      await DataTablesApi.copyForward(resourceId, 'Company', 'dividends', newValue, month.toString(), 'value', toMonth.toString());
  }

  return <DataTable
    initializeTable={initializeTable}
    columnsHeader=''
    tableHeader={tableHeader}
    commitCellUpdate={commitCellUpdate}
    noRowsToDisplay={noRowsToDisplay}
    noMonthsToDisplay={noMonthsToDisplay}
    onCellCopyForward={onCellCopyForward}
    cellHint='£'
    timePeriodTotals={TimePeriodTotals.Yearly}
    headerMonthFormat='MMM'
  />
}


