import * as Http from "../../utils/http";

export async function fetchOtherIncome() {
  const [json,] = await Http.get('/other_income.json');
  return json;
}

export async function updateIncomeMonth(id: string, newValue: string, field: string = 'value') {
  const [json,] = await Http.patch(`/other_income_months/${id}`, {
    other_income_month: { [field]: newValue }
  });
  return json;
}
