import * as React from 'react'
import { CurrencyInputWithLabel, CurrencyInput } from '../CurrencyInput'
import { isZero, currencyToNumber, numberToCurrency } from '../../../misc/currency'
import DebtorsTable from './DebtorsTable'
import { CurrentAssets, OpeningBalanceSheetData } from '../../modules/opening-balance-sheet/types'

interface Error {
  [key: string]: string | undefined
}

function CurrentAssetsCard(
  props: {openingBalanceSheet: OpeningBalanceSheetData, setParent: Function}
) {
  const [currentAssets, setCurrentAssets] = React.useState<CurrentAssets>(props.openingBalanceSheet.currentAssets)
  const [error, setError] = React.useState<Error>({})

  const validateTradeDebtorsTotal = (assets: CurrentAssets) => {
    const total = [
      currencyToNumber(assets.tradeDebtorsMonth1),
      currencyToNumber(assets.tradeDebtorsMonth2),
      currencyToNumber(assets.tradeDebtorsMonth3),
      currencyToNumber(assets.tradeDebtorsMonth4),
    ].reduce((sum, month) => sum + month)

    if (total !== currencyToNumber(assets.tradeDebtorsTotal)) {
      setError({ ...error, tradeDebtorsTotal: 'Received month splits must equal the total amount.' })
    } else {
      setError({ ...error, tradeDebtorsTotal: undefined })
    }
  }

  const updateField = (field: string) => (value: any) => {
    const clone = {...currentAssets}
    clone[field] = value
    setCurrentAssets(clone)
    props.setParent(clone)

    validateTradeDebtorsTotal(clone)
  }

  return (
    <div className='card mb-4'>
      <div className='card-header'>
        Current Assets
      </div>
      <div className='card-body'>
        <CurrencyInputWithLabel
          label='Stock'
          value={currentAssets.stock}
          setHook={updateField('stock')}
        />
        <div className='form-row form-group'>
          <div className='col-2 col-form-label' style={{marginTop: '30px'}}>
            <label>Trade Debtors</label>
          </div>
          <div className='col-2'>
            <label><strong>Total</strong></label>
            <CurrencyInput
              value={currentAssets.tradeDebtorsTotal}
              setHook={updateField('tradeDebtorsTotal')}
            />
          </div>
          <div className='col-2'>
            <label><strong>Received month 1</strong></label>
            <CurrencyInput
              value={currentAssets.tradeDebtorsMonth1}
              setHook={updateField('tradeDebtorsMonth1')}
              className={error.tradeDebtorsTotal && 'is-invalid'}
            />
          </div>
          <div className='col-2'>
            <label><strong>Received month 2</strong></label>
            <CurrencyInput
              value={currentAssets.tradeDebtorsMonth2}
              setHook={updateField('tradeDebtorsMonth2')}
              className={error.tradeDebtorsTotal && 'is-invalid'}
            />
          </div>
          <div className='col-2'>
            <label><strong>Received month 3</strong></label>
            <CurrencyInput
              value={currentAssets.tradeDebtorsMonth3}
              setHook={updateField('tradeDebtorsMonth3')}
              className={error.tradeDebtorsTotal && 'is-invalid'}
            />
          </div>
          <div className='col-2'>
            <label><strong>Received month 4</strong></label>
            <CurrencyInput
              value={currentAssets.tradeDebtorsMonth4}
              setHook={updateField('tradeDebtorsMonth4')}
              className={error.tradeDebtorsTotal && 'is-invalid'}
            />
          </div>
          {error.tradeDebtorsTotal && (
            <div className='col offset-4'>
              <div className="small text-danger">{error.tradeDebtorsTotal}</div>
            </div>
          )}
        </div>
        <CurrencyInputWithLabel
          label='Cash in Bank'
          value={currentAssets.cashInBank}
          setHook={updateField('cashInBank')}
          disabled={!isZero(props.openingBalanceSheet.currentLiabilities.overdraft)}
        />
        <div className='row'>
          <div className='col-2 col-form-label'>
            Other Debtors
          </div>
          <div className='col-9'>
            <DebtorsTable
              debtors={currentAssets.debtors}
              setParent={updateField('debtors')}
            />
          </div>
        </div>
        <CurrencyInputWithLabel
          label='Auto-Balance Correction'
          value={currentAssets.autoBalanceCorrection}
          setHook={updateField('autoBalanceCorrection')}
          disabled={true}
        />
        <strong>
          <CurrencyInputWithLabel
            label='Total'
            value={currentAssets.total}
            setHook={updateField('total')}
            disabled={true}
          />
        </strong>
      </div>
    </div>
  )
}

export default CurrentAssetsCard
