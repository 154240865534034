import * as React from "react";
import { Moment } from "moment";
import DataTable, {TimePeriodTotals} from "../components/DataTable";
import * as OtherCreditorsApi from '../modules/other-creditors/api';
import * as DataTablesApi from "../modules/datatables/api";

const noRowsToDisplay = (
  <tr>
    <th className='text-center py-4 text-wrap'>
      <p>No other creditors to display.</p>

      <p className='mb-0'>
        <a href='/other_creditors/new'>Click here</a> to create a new other creditor.
      </p>
    </th>
  </tr>
);

const noMonthsToDisplay = () => (
  <tr>
    <th className='text-center py-4 text-wrap'>
      <p>No other creditor amounts to display.</p>
    </th>
  </tr>
);

const tableHeader = (
  <div>
    <i className="fas fa-pound-sign fa-fw text-muted mr-2"></i>
    Other Creditors
  </div>
)

export default function OtherCreditorsDataTable() {
  const initializeTable = async () => {
    return await OtherCreditorsApi.fetchCreditors({ scope: OtherCreditorsApi.OtherCreditorsScope.All });
  }

  const commitCellUpdate = async (newValue: string, rowId: string, cellId: string) => {
    await OtherCreditorsApi.updateCreditorMonth(cellId, newValue);
  }

  const onCellCopyForward = async (newValue: string, rowId: string, cellId: string, month: Moment, resourceType?: string) => {
    if (resourceType !== undefined)
      await DataTablesApi.copyForward(rowId, resourceType, 'monthly_amounts', newValue, month.toString());
  }

  return <DataTable
    initializeTable={initializeTable}
    columnsHeader=''
    tableHeader={tableHeader}
    commitCellUpdate={commitCellUpdate}
    noRowsToDisplay={noRowsToDisplay}
    noMonthsToDisplay={noMonthsToDisplay}
    onCellCopyForward={onCellCopyForward}
    cellHint='£'
    timePeriodTotals={TimePeriodTotals.Monthly}
    hasOpeningBalance
  />
}
