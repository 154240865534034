import * as Http from "../../utils/http";

export async function fetchDividends() {
  const [json,] = await Http.get('/dividends.json');
  return json;
}

export async function updateDividend(id: string, newValue: string) {
  const [json,] = await Http.patch(`/dividends/${id}`, {
    dividend: { value: newValue }
  });
  return json;
}