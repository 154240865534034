import * as React from "react";
import { Moment } from "moment";
import DataTable, {TimePeriodTotals} from "../components/DataTable";
import * as OtherIncomeApi from '../modules/other-income/api';
import * as DataTablesApi from "../modules/datatables/api";

const noRowsToDisplay = (
  <tr>
    <th className='text-center py-4 text-wrap'>
      <p>No other income to display.</p>

      <p className='mb-0'>
        <a href='/other_income/new'>Click here</a> to create a new other income.
      </p>
    </th>
  </tr>
);

const tableHeader = (
  <div>
    <i className="fas fa-receipt fa-fw text-muted mr-2"></i>
    Other Income
  </div>
)

export default function OtherIncomeDataTable() {
  const initializeTable = async () => {
    return await OtherIncomeApi.fetchOtherIncome();
  }

  const commitCellUpdate = async (newValue: string, rowId: string, cellId: string) => {
    await OtherIncomeApi.updateIncomeMonth(cellId, newValue);
  }

  const onCellCopyForward = async (newValue: string, rowId: string, cellId: string, month: Moment) => {
    await DataTablesApi.copyForward(rowId, 'OtherIncome', 'other_income_months', newValue, month.toString());
  }

  return <DataTable
    initializeTable={initializeTable}
    columnsHeader=''
    tableHeader={tableHeader}
    commitCellUpdate={commitCellUpdate}
    noRowsToDisplay={noRowsToDisplay}
    onCellCopyForward={onCellCopyForward}
    cellHint='£'
    timePeriodTotals={TimePeriodTotals.Both}
  />
}
