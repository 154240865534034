import * as Http from "../../utils/http";

export async function copyForward(
  rowId: string,
  rowResourceType: string,
  childRelationType: string,
  value: string | number,
  month: string,
  field: string = 'value',
  toMonth: string | undefined = undefined,
) {
  const [json,] = await Http.post('/datatables/copy_forward', {
    resource: rowResourceType,
    resource_id: rowId,
    association: childRelationType,
    from_month: month,
    to_month: toMonth,
    value,
    field,
  });
  return json;
}