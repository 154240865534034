import * as React from 'react'
import { uuid } from '../utils/uuid'
import { numberToCurrency, currencyToNumber } from '../../misc/currency'

interface CurrencyInputProps {
  setHook: Function
  [otherProps: string]: any
}

export function CurrencyInput({setHook, id = uuid(), className = '', ...otherProps}: CurrencyInputProps) {
  return <input
    id={id}

    className={`form-control string ${className}`}

    onFocus={e => {
      let value = e.target.value
      value = value ? String(currencyToNumber(value).toFixed(2)) : ''
      setHook(value)
      setTimeout(() => e.target.select(), 0);
    }}

    onChange={e => setHook(e.target.value)}

    onBlur={e => {
      setHook(numberToCurrency(e.target.value))
    }}

    {...otherProps}
  />
}

interface CurrencyInputWithLabelProps {
  label: string,
  value: string,
  setHook: Function,
  disabled?: boolean
}

export function CurrencyInputWithLabel({label, value, setHook, disabled = false}: CurrencyInputWithLabelProps) {
  const id = uuid()
  return (
    <div className='form-group row string'>
      <label className='col-2 col-form-label string' htmlFor={id}>
        {label}
      </label>
      <div className='col-3'>
        <CurrencyInput
          id={id}
          value={value}
          setHook={setHook}
          disabled={disabled}
        />
      </div>
    </div>
  )
}
