import * as Http from "../../utils/http";

export async function updateSale(id: string, newValue: string, field: string = 'value') {
  Http.patch(`/sales/${id}`, { sale: { [field]: newValue } });
}

export async function copySaleValueForward(productId: string, saleId: string, valueToCopy: string) {
  const [{ success },] = await Http.post(`/products/${productId}/copy_sales_forward`,
    { saleId, valueToCopy }
  );

  if (success === true)
    return Promise.resolve();
  else
    return Promise.reject();
}