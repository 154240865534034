import * as React from "react";
import { Moment } from "moment";
import DataTable, {TimePeriodTotals} from "../components/DataTable";
import * as StocksApi from '../modules/stocks/api';
import * as DataTablesApi from "../modules/datatables/api";

// technically impossible
const noRowsToDisplay = <React.Fragment></React.Fragment>;

const noMonthsToDisplay = () => (
  <tr>
    <th className='text-center py-4 text-wrap'>
      <p>No stocks to display.</p>
    </th>
  </tr>
);

const tableHeader = (
  <div>
    <i className="fas fa-calendar-alt fa-fw text-muted mr-2"></i>
      Stock days to pay
  </div>
);

export default function StockDaysToPayDataTable() {
  const field = 'days_to_pay'

  const initializeTable = async () => {
    return await StocksApi.fetchStocks(field);
  }

  const commitCellUpdate = async (newValue: string, rowId: string, cellId: string) => {
    await StocksApi.updateStock(cellId, newValue, field);
  }

  const onCellCopyForward = async (newValue: string, rowId: string, cellId: string, month: Moment) => {
    await DataTablesApi.copyForward(rowId, 'Company', 'stocks', newValue, month.toString(), field);
  }

  return <DataTable
      initializeTable={initializeTable}
      columnsHeader=''
      tableHeader={tableHeader}
      commitCellUpdate={commitCellUpdate}
      noRowsToDisplay={noRowsToDisplay}
      noMonthsToDisplay={noMonthsToDisplay}
      onCellCopyForward={onCellCopyForward}
      timePeriodTotals={TimePeriodTotals.None}
  />
}
