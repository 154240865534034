import * as Http from "../../utils/http";

export async function fetchStocks(valueField: string = 'value') {
  const [json,] = await Http.get(`/stocks.json?value_field=${valueField}`);
  return json;
}

export async function updateStock(id: string, newValue: string, field: string = 'value') {
  const [json,] = await Http.patch(`/stocks/${id}`, {
    stock: { [field]: newValue }
  });
  return json;
}
