import * as React from "react";
import { Moment } from "moment";
import DataTable, {TimePeriodTotals} from "../components/DataTable";
import * as OverheadsApi from '../modules/overheads/api';
import * as DataTablesApi from "../modules/datatables/api";

const noRowsToDisplay = (
  <tr>
    <th className='text-center py-4 text-wrap'>
      <p>No overheads to display.</p>

      <p className='mb-0'>
        <a href='/overheads/new'>Click here</a> to create a new overhead.
      </p>
    </th>
  </tr>
);

const noMonthsToDisplay = () => (
  <tr>
    <th className='text-center py-4 text-wrap'>
      <p>No monthly costs to display.</p>
    </th>
  </tr>
);

const tableHeader = (title: string) => (
  <div>
    <i className="fas fa-pound-sign fa-fw text-muted mr-2"></i>
    {title}
  </div>
)

type Props = {
  scope: OverheadsApi.OverheadsScope,
  title: string,
}

export default function OverheadsDataTable({
  scope,
  title
}: Props) {

  const initializeTable = async () => {
    return await OverheadsApi.fetchOverheads({ scope: scope });
  }

  const commitCellUpdate = async (newValue: string, rowId: string, cellId: string) => {
    await OverheadsApi.updateOverheadMonth(cellId, newValue);
  }

  const onCellCopyForward = async (newValue: string, rowId: string, cellId: string, month: Moment) => {
    await DataTablesApi.copyForward(rowId, 'Overhead', 'monthly_costs', newValue, month.toString());
  }

  return <DataTable
    initializeTable={initializeTable}
    columnsHeader=''
    tableHeader={tableHeader(title)}
    commitCellUpdate={commitCellUpdate}
    noRowsToDisplay={noRowsToDisplay}
    noMonthsToDisplay={noMonthsToDisplay}
    onCellCopyForward={onCellCopyForward}
    cellHint='£'
    timePeriodTotals={TimePeriodTotals.Both}
  />
}


