import * as React from "react";
import { Moment } from "moment";
import DataTable, {TimePeriodTotals} from "../../components/DataTable";
import * as ProductsApi from '../../modules/products/api';

const noProductsToDisplay = (
  <tr>
    <th className='text-center py-4 text-wrap'>
      <p>No products to display.</p>

      <p className='mb-0'>
        <a href='/products/new'>Click here</a> to create a new product.
      </p>
    </th>
  </tr>
);

const tableHeader = (
  <div>
    <i className="fas fa-chart-line fa-fw text-muted mr-2"></i>
    Gross Profit
  </div>
)

const useForceUpdate = () => {
  const [updateKey, setUpdateKey] = React.useState(0);
  const forceUpdate = () => setUpdateKey(key => key + 1);

  return {
    updateKey,
    forceUpdate
  }
}

export default function GrossProfitDataTable() {
  const { updateKey, forceUpdate } = useForceUpdate();

  React.useEffect(() => {
    document.addEventListener('data_table:change', onDataTableChange);

    return () => {
      document.removeEventListener('data_table:change', onDataTableChange);
    }
  })

  const onDataTableChange = () => forceUpdate();

  const initializeTable = async () => {
    return await ProductsApi.fetchProducts('sales', 'gross_profit');
  }

  return <DataTable
    key={updateKey}
    initializeTable={initializeTable}
    columnsHeader='Product/Service'
    tableHeader={tableHeader}
    noRowsToDisplay={noProductsToDisplay}
    timePeriodTotals={TimePeriodTotals.Both}
    cellHint='£'
    readOnly
  />
}
