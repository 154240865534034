const format = (cents: number | string) => {
  const poundsAndPence = (parseInt(cents.toString()) / 100).toFixed(2);
  return poundsAndPence === "NaN" ? "0" : poundsAndPence;
}

const parse = (poundsAndPence: number | string) => {
  return parseFloat(poundsAndPence.toString()) * 100;
}

export default {
  format,
  parse
}