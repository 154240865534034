import * as React from "react";
import { Moment } from "moment";
import DataTable, {TimePeriodTotals} from "../components/DataTable";
import { Overhead } from "../modules/overheads/types"
import * as OverheadsApi from '../modules/overheads/api';
import * as DataTablesApi from "../modules/datatables/api";

// technically impossible
const noRowsToDisplay = <React.Fragment></React.Fragment>;

const tableHeader = (
  <div>
    <i className="fas fa-calendar-alt fa-fw text-muted mr-2"></i>
    Overhead days to pay
  </div>
)

type Props= {
  overhead: Overhead,
}

export default function OverheadDaysToPayDataTable({
  overhead,
}: Props) {

  const field = 'days_to_pay'

  const initializeTable = async () => {
    return await OverheadsApi.fetchOverhead(overhead.id, field);
  }

  const commitCellUpdate = async (newValue: string, rowId: string, cellId: string) => {
    await OverheadsApi.updateOverheadMonth(cellId, newValue, field);
  }

  const onCellCopyForward = async (newValue: string, rowId: string, cellId: string, month: Moment) => {
    await DataTablesApi.copyForward(rowId, 'Overhead', 'monthly_costs', newValue, month.toString(), field);
  }

  return <DataTable
    initializeTable={initializeTable}
    columnsHeader='Days to pay'
    tableHeader={tableHeader}
    commitCellUpdate={commitCellUpdate}
    noRowsToDisplay={noRowsToDisplay}
    onCellCopyForward={onCellCopyForward}
    timePeriodTotals={TimePeriodTotals.None}
  />
}


