import * as React from 'react'
import Select from 'react-select'
import { FixedAssetCategory } from '../../modules/opening-balance-sheet/types'

interface Option {
  value: string
  label: string
}

function FixedAssetCategorySelect(
  props: {value: FixedAssetCategory, setHook: (category: FixedAssetCategory) => void}
) {
  const [error, setError] = React.useState<any>(null)
  const [options, setOptions] = React.useState<Option[]>([])
  const [value, setValue] = React.useState<Option>({} as Option)

  React.useEffect(() => {
    fetch('/fixed_asset_categories.json')
      .then(res => res.json())
      .then(
        (result) => {
          const asOptions = result.map((category: FixedAssetCategory): Option => {
            return {value: category.id, label: category.name}
          })
          setOptions(asOptions)

          // Need to search the options for the props.value id:
          // https://github.com/JedWatson/react-select/issues/3063
          const selectedOption: Option = asOptions.filter((option: Option) =>
            props.value?.id === option.value
          )[0]
          setValue(selectedOption)
        },
        (error) => {
          setError(error)
        }
      )
  }, [])

  const handleOnChange = (option: any) => {
    props.setHook({ id: option.value, name: option.label })
    setValue(option)
  }

  if (error) {
    return <>
      <div>
        Error: {error.message}
      </div>
    </>
  } else {
    return <>
      <Select
        onChange={handleOnChange}
        options={options}
        value={value}
      />
    </>
  }
}

export default FixedAssetCategorySelect
