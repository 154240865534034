import * as React from 'react';
import moment from 'moment';
import {TimePeriodTotals} from "../DataTable";

type Props = {
  type: string,
  monthsForYear: moment.Moment[],
  timePeriodTotals: TimePeriodTotals
}

export default function TaxAssumptionsHeaderRow({ type, monthsForYear, timePeriodTotals }: Props) {
  return (
    <React.Fragment>
      <tr>
        <th></th>

        {monthsForYear.map((month, index) => {
          const isNewYear = index !== 0 && index % 12 === 0;
          const monthFormat = month.format('MMM YYYY');

          return (
              <React.Fragment key={monthFormat}>
                <th className={isNewYear ? 'yearDivider' : ''} style={{textAlign: 'center'}} colSpan={2}>
                  {monthFormat}
                </th>
              </React.Fragment>
          )
        })}
      </tr>
      <tr>
        <th>{type}</th>

        {monthsForYear.map((month, index) => {
          const isNewYear = index !== 0 && index % 12 === 0;
          const monthFormat = month.format('MMM YYYY');

          return (
              <React.Fragment key={monthFormat}>
                <th className={isNewYear ? 'yearDivider' : ''} style={{textAlign: 'center'}}>
                  Threshold
                </th>
                <th style={{textAlign: 'center'}}>
                  Rate
                </th>
              </React.Fragment>
          )
        })}
      </tr>
    </React.Fragment>
  );
}