import * as React from 'react'
import { numberToCurrency, currencyToNumber } from '../../misc/currency'
import FixedAssetsCard from '../components/opening-balance-sheet/FixedAssetsCard'
import CurrentAssetsCard from '../components/opening-balance-sheet/CurrentAssetsCard'
import CurrentLiabilitiesCard from '../components/opening-balance-sheet/CurrentLiabilitiesCard'
import AdditionalFieldsCard from '../components/opening-balance-sheet/AdditionalFieldsCard'
import {
  OpeningBalanceSheetData, FixedAsset, CurrentAssets, Debtor,
  CurrentLiabilities, Creditor, ExistingLoan, AdditionalFields
} from '../modules/opening-balance-sheet/types'
import * as OpeningBalanceSheetApi from '../modules/opening-balance-sheet/api';
import { apiToState, stateToApi } from '../modules/opening-balance-sheet/interface-converter';

interface Error {
  message: string
  details?: string[]
}

function OpeningBalanceSheet() {
  const [data, setData] = React.useState({} as OpeningBalanceSheetData)
  const [error, setError] = React.useState<Error>()
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    OpeningBalanceSheetApi.fetchOpeningBalanceSheet().then(result => {
      setData(apiToState(result))
      setIsLoaded(true)
    },
    (error) => {
      setError({ message: 'Failed to load Opening Balance Sheet.' })
    })
  }, [])

  const updateDataField = (field: string) => (value: any) => {
    const clone: OpeningBalanceSheetData = {...data}
    clone[field] = value

    const unbalancedNetAssets = calculateUnbalancedNetAssets(clone)
    const equity = calculateEquity(clone.additionalFields)

    const assetsCorrection = calculateAssetsCorrection(equity, unbalancedNetAssets)
    const liabilitiesCorrection = calculateLiabilitiesCorrection(equity, unbalancedNetAssets)

    const totalAssets = calculateAssetsTotal(clone, assetsCorrection)
    const totalLiabilities = calculateCurrentLiabilitiesTotal(clone.currentLiabilities, liabilitiesCorrection)

    clone.currentAssets.autoBalanceCorrection = numberToCurrency(assetsCorrection)
    clone.currentLiabilities.autoBalanceCorrection = numberToCurrency(liabilitiesCorrection)

    clone.currentAssets.total = numberToCurrency(totalAssets)
    clone.currentLiabilities.total = numberToCurrency(totalLiabilities)


    clone.additionalFields.netAssets = calculateNetAssets(totalAssets, totalLiabilities)

    setData(clone)
  }

  const calculateAssetsTotal = (openingBalanceSheet: OpeningBalanceSheetData, assetsCorrection: number) => {
    return calculateUnbalancedAssets(openingBalanceSheet) + assetsCorrection
  }

  const calculateCurrentLiabilitiesTotal = (currentLiabilities: CurrentLiabilities, liabilitiesCorrection: number) => {
    return calculateUnbalancedLiabilities(currentLiabilities) + liabilitiesCorrection
  }

  const calculateNetAssets = (totalAssets: number, totalLiabilities: number) => {
    return numberToCurrency(totalAssets - totalLiabilities)
  }

  const calculateEquity = (additionalFields: AdditionalFields) => {
    const reserves = currencyToNumber(additionalFields.reserves)
    const shareCapital = currencyToNumber(additionalFields.shareCapital)
    return reserves + shareCapital
  }

  const calculateAssetsCorrection = (equity: number, unbalancedNetAssets: number) => {
    if (equity - unbalancedNetAssets < 0) {
      return 0
    }
    return equity - unbalancedNetAssets
  }

  const calculateLiabilitiesCorrection = (equity: number, unbalancedNetAssets: number) => {
    if (equity - unbalancedNetAssets > 0) {
      return 0
    }
    return unbalancedNetAssets - equity
  }

  const calculateUnbalancedCurrentAssets = (currentAssets: CurrentAssets) => {
    const values = [
      Number(currencyToNumber(currentAssets.stock)),
      Number(currencyToNumber(currentAssets.tradeDebtorsTotal)),
      Number(currencyToNumber(currentAssets.cashInBank)),
    ]
    let total = values.reduce((sum, value) => sum + value)
    currentAssets.debtors.forEach((debtor: Debtor) => {
      if (!debtor._destroy) {
        total += currencyToNumber(debtor.amount)
      }
    })
    return total
  }

  const calculateUnbalancedAssets = (openingBalanceSheet: OpeningBalanceSheetData) => {
    let fixedAssetsTotal = 0
    openingBalanceSheet.fixedAssets.forEach((fixedAsset: FixedAsset) => {
      if (!fixedAsset._destroy) {
        fixedAssetsTotal += currencyToNumber(fixedAsset.bookValue)
      }
    })
    return calculateUnbalancedCurrentAssets(openingBalanceSheet.currentAssets) + fixedAssetsTotal
  }

  const calculateUnbalancedLiabilities = (currentLiabilities: CurrentLiabilities) => {
    const values = [
      Number(currencyToNumber(currentLiabilities.overdraft)),
      Number(currencyToNumber(currentLiabilities.invoiceDiscounting)),
      Number(currencyToNumber(currentLiabilities.tradeCreditorsTotal)),
      Number(currencyToNumber(currentLiabilities.payeNi)),
      Number(currencyToNumber(currentLiabilities.vat)),
      Number(currencyToNumber(currentLiabilities.corporationTax)),
    ]
    let total = values.reduce((sum, value) => sum + value)
    currentLiabilities.creditors.forEach((creditor: Creditor) => {
      if (!creditor._destroy) {
        total += currencyToNumber(creditor.amount)
      }
    })
    currentLiabilities.existingLoans.forEach((existingLoan: ExistingLoan) => {
      if (!existingLoan._destroy) {
        total += currencyToNumber(existingLoan.amountOutstanding)
      }
    })
    return total
  }

  const calculateUnbalancedNetAssets = (openingBalanceSheet: OpeningBalanceSheetData) => {
    return calculateUnbalancedAssets(openingBalanceSheet) - calculateUnbalancedLiabilities(openingBalanceSheet.currentLiabilities)
  }

  const onSave = () => {
    setSubmitting(true)
    OpeningBalanceSheetApi.updateOpeningBalanceSheet(stateToApi(data)).then(([json, response]) => {
      if (response.ok) {
        window.location.href = json.url;
      } else {
        setError({
          message: 'An error occurred attempting to save the Opening Balance Sheet:',
          details: json.errors
        })
        window.scrollTo(0, 0);
      }
      setSubmitting(false)
    })
  }

  if (error && !isLoaded) {
    return <div role='alert' className='fade alert alert-danger show'>
             {error.message}
           </div>
  } if (!isLoaded) {
    return <div className="spinner-border" role="status">
             <span className="sr-only">Loading...</span>
           </div>
  } else {
    return <>
      {error &&
        <div role='alert' className='fade alert alert-danger show'>
          {error.message}
          {
            error.details?.map((error, index) => {
              return <li key={index}>
                {error}
              </li>
            })
          }
        </div>
      }
      <FixedAssetsCard
        fixedAssets={data.fixedAssets}
        setParent={updateDataField('fixedAssets')}
      />
      <CurrentAssetsCard
        openingBalanceSheet={data}
        setParent={updateDataField('currentAssets')}
      />
      <CurrentLiabilitiesCard
        openingBalanceSheet={data}
        setParent={updateDataField('currentLiabilities')}
      />
      <AdditionalFieldsCard
        additionalFields={data.additionalFields}
        setParent={updateDataField('additionalFields')}
      />
      <div className='card mb-4'>
        <div className='card-body text-right'>
          <input type='submit' value='Save' className='btn btn-primary px-5' onClick={onSave} disabled={submitting} />
        </div>
      </div>
    </>
  }
}

export default OpeningBalanceSheet
