import * as React from "react";
import moment, { Moment } from "moment";
import Cell from "./data-table/Cell";
import FooterRow from "./data-table/FooterRow";
import HeaderRow from "./data-table/HeaderRow";
import { monthRange } from "../utils/dates";
import { parseNumber, numberWithCommas } from "../../misc/number_formatter";

export type CellValueType = string;

export type ColumnData = {
  month: Moment,
  value: CellValueType,
  cellHint?: string
}

export type CellsCollection = {
  [cellId: string]: ColumnData
}

export type RowData = {
  name: string,
  link: string,
  cells: CellsCollection,
  editable?: boolean,
  resource_id?: string,
  resource_type?: string,
  opening_balance?: string,
  end_date?: string,
  [otherProps: string]: any
}

export type DataRowsCollection = {
  [parentId: string]: RowData
}

export type TableValues = {
  start_month: Moment,
  end_month: Moment,
  collection: DataRowsCollection,
  month_totals: MonthTotals,
  year_totals: YearTotals
}

export type MonthTotals = {
  [month: string]: number
}

export type ValueYearTotal = {
  [month: string]: number
}

export type YearTotal = {
  start: Moment,
  end: Moment,
  values: ValueYearTotal,
  total: number
}

export type YearTotals = {
  [yearNo: number]: YearTotal
}

export enum TimePeriodTotals {
  None,
  Monthly,
  Yearly,
  Both,
}

type Props= {
  cellHint?: string,
  columnsHeader: string,
  tableHeader: React.ReactElement,
  noRowsToDisplay: React.ReactElement,
  noMonthsToDisplay?: () => React.ReactElement,
  initializeTable: () => Promise<TableValues>,
  onCellUpdate?: (newValue: CellValueType, parentId: string, childId: string) => void,
  onCellCopyForward?: (newValue: CellValueType,
                       parentId: string,
                       childId: string,
                       month: Moment,
                       resourceType?: string,
                       resourceId?: string,
                       toMonth?: Moment) => Promise<any>,
  commitCellUpdate?: (value: CellValueType, parentId: string, childId: string) => Promise<any>,
  timePeriodTotals: TimePeriodTotals,
  readOnly?: boolean,
  yearTotalsEvaluator?: (year: number, rowId?: string) => string,
  monthTotalsEvaluator?: (month: Moment) => string,
  hasOpeningBalance?: boolean,
  headerMonthFormat?: string,
  rowHeaderContent?: (rowData: RowData) => React.ReactElement
}

export default function DataTable({
  cellHint,
  columnsHeader,
  tableHeader,
  noRowsToDisplay,
  noMonthsToDisplay,
  initializeTable,
  commitCellUpdate,
  onCellUpdate,
  onCellCopyForward,
  timePeriodTotals,
  readOnly,
  yearTotalsEvaluator,
  monthTotalsEvaluator,
  hasOpeningBalance,
  headerMonthFormat,
  rowHeaderContent,
}: Props) {

  const [collection, setCollection] = React.useState({} as DataRowsCollection);
  const [startMonth, setStartMonth] = React.useState(moment('2021-01-01'));
  const [endMonth, setEndMonth] = React.useState(moment('2021-12-01'));
  const [isLoading, setIsLoading] = React.useState(false);

  const monthsForYear = monthRange(startMonth, endMonth);

  React.useEffect(() => {
    setIsLoading(true);

    initializeTable().then(({ start_month, end_month, collection, month_totals, year_totals }) => {
      setCollection(collection);
      setStartMonth(moment(start_month));
      setEndMonth(moment(end_month));

      setIsLoading(false);
    });
  }, []);

  // handlers

  const handleCellUpdate = (rowId: string, cellId: string) => (newValue: string) => {
    const collectionCopy = { ...collection };
    collectionCopy[rowId].cells[cellId].value = newValue;
    setCollection(collectionCopy);

    if (onCellUpdate)
      onCellUpdate(newValue, rowId, cellId);
  }

  const onCellBlur = (rowId: string, cellId: string) => () => {
    if (commitCellUpdate) {
      setIsLoading(true);
      commitCellUpdate(collection[rowId].cells[cellId].value, rowId, cellId)
          .then(initializeTable)
          .then(({ collection }) => {
            setCollection(collection);
            setIsLoading(false);
          });
    }
  }

  const handleCopyForward = (rowId: string, cellId: string) => () => {
    if (onCellCopyForward) {
      setIsLoading(true);

      const cellValue = collection[rowId].cells[cellId].value;
      const row = { ...collection[rowId] };
      const resourceId = row.resource_id;
      const resourceType = row.resource_type;
      const toMonth = row.end_date ? moment(row.end_date) : undefined;
      const { month, value } = row.cells[cellId];

      row.cells = Object.entries(row.cells).reduce((cells, [id, cell]) => ({
        ...cells,
        [id]: { ...cell, value: cell.month > month ? cellValue : cell.value }
      }), {})

      setCollection({ ...collection, [rowId]: row });

      onCellCopyForward(value, rowId, cellId, month, resourceType, resourceId, toMonth)
        .then(initializeTable)
        .then(({ collection }) => {
          setCollection(collection);
          setIsLoading(false);
        });
    }
  }

  // presentation

  const renderTableRow = ([rowId, { name, link, cells, editable, opening_balance, ...otherProps }]: [string, RowData]) => {
    return (
      <tr key={rowId}>
        <th className="align-middle">
          {rowHeaderContent ? (
            rowHeaderContent({ name, link, otherProps, cells })
          ) : (
            <>
              {link && <a href={link} className='text-wrap'>{name}</a>}
              {(link == undefined || link === '') && name}
            </>
          )}
        </th>

        {
          Object.entries(cells).map(([cellId, { month, value }], index) => {
            const isNewYear = index !== 0 && index % 12 === 0;
            const isEndOfYear = index % 12 === 11;
            const isFirstMonth = index === 0;

            let yearTotal = '';

            if (isEndOfYear) {
              if (yearTotalsEvaluator) {
                yearTotal = yearTotalsEvaluator((index + 1) / 12, rowId)
               } else {
                yearTotal = numberWithCommas(Object.entries(cells).slice(index - 11, index + 1)
                    .map(([cellId, { month, value }], index) => parseNumber(value.toString()))
                    .reduce((sum, current) => sum + current, 0));
              }
            }

            return (<React.Fragment key={index}>
              {
                hasOpeningBalance && isFirstMonth && <th className='cell'>
                  <div className='cell-no-padding'>
                    <div className='cell-width' />
                    {cellHint && <span className="cell-hint-text">{cellHint}</span>}
                    <input type="text" readOnly={true}
                           className="no-highlight total-input"
                           value={opening_balance}
                           style={{width: "100%", textAlign: 'right'}}/>
                  </div>
                </th>
              }
              <Cell
                    className={isNewYear ? 'yearDivider' : ''}
                    value={value}
                    onChange={handleCellUpdate(rowId, cellId)}
                    onBlur={onCellBlur(rowId, cellId)}
                    cellHint={cellHint}
                    onCopyForward={handleCopyForward(rowId, cellId)}
                    readOnly={readOnly || editable === false}
                  />
              {
                isEndOfYear && (timePeriodTotals === TimePeriodTotals.Both || timePeriodTotals === TimePeriodTotals.Yearly) && <th className='yearDivider cell'>
                  <div className='cell-no-padding'>
                    <div className='cell-width' />
                    {cellHint && <span className="cell-hint-text">{cellHint}</span>}
                    <input type="text" readOnly={true}
                           className="no-highlight total-input"
                           value={yearTotal}
                           style={{width: "100%", textAlign: 'right'}}/>
                  </div>
                </th>}
            </React.Fragment>);
          })
        }
      </tr>
    );
  }

  const renderTable = () => {
    if (Object.values(collection).length === 0)
      return noRowsToDisplay;

    else if ((monthsForYear.length === 0 || Object.values(Object.values(collection)[0].cells).length === 0) && noMonthsToDisplay)
      return noMonthsToDisplay();

    else
      return (<React.Fragment>{Object.entries(collection).map(renderTableRow)}</React.Fragment>)
  }

  // view

  return (
    <div className="card">
      <div className="card-header d-flex justify-content-between">
        <div className="align-self-center">
          {tableHeader}
        </div>
      </div>

      <div className="table-scroll data-table">
        <table className="table table-bordered" style={{tableLayout: 'fixed'}}>
          <thead>
            <HeaderRow
              monthsForYear={monthsForYear}
              type={columnsHeader}
              timePeriodTotals={timePeriodTotals}
              hasOpeningBalance={hasOpeningBalance}
              monthFormat={headerMonthFormat}
            />
          </thead>
          <tbody>
            {renderTable()}
          </tbody>
          {(timePeriodTotals === TimePeriodTotals.Both || timePeriodTotals === TimePeriodTotals.Monthly) &&
            <tfoot>
              <FooterRow
                collection={collection}
                monthsForYear={monthsForYear}
                cellHint={cellHint}
                timePeriodTotals={timePeriodTotals}
                yearTotalsEvaluator={yearTotalsEvaluator}
                monthTotalsEvaluator={monthTotalsEvaluator}
                hasOpeningBalance={hasOpeningBalance}
              />
            </tfoot>
          }
        </table>
      </div>

      {isLoading &&
        <div className="data-table-loading">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      }
    </div>
  )
}
