import * as React from 'react';
import moment from 'moment';
import {DataRowsCollection, MonthTotals, TimePeriodTotals} from "../DataTable";
import { parseNumber, numberWithCommas } from "../../../misc/number_formatter";

type Props = {
  cellHint?: string,
  collection: DataRowsCollection,
  monthsForYear: moment.Moment[],
  timePeriodTotals: TimePeriodTotals,
  yearTotalsEvaluator?: (year: number, rowId?: string) => string,
  monthTotalsEvaluator?: (month: moment.Moment) => string,
  hasOpeningBalance?: boolean
}

export default function FooterRow({ cellHint, collection, monthsForYear, timePeriodTotals, yearTotalsEvaluator, monthTotalsEvaluator, hasOpeningBalance }: Props) {
  const cells = Object.entries(collection).flatMap(([parentId, row], index) => {
      return Object.entries(row.cells).flatMap(([cellId, columnData], index) => {
          return columnData;
      });
    });

  const calculateYearTotal = (monthsForYear: moment.Moment[], year: number) => {
    if (yearTotalsEvaluator) {
      return yearTotalsEvaluator(year);
    } else {
      return numberWithCommas(cells.filter((c) => {
               return moment(c.month).isBetween(monthsForYear[0], monthsForYear[monthsForYear.length - 1], undefined, '[]');
             }).map((c) => parseNumber(c.value.toString()))
               .reduce((sum, current) => sum + current, 0));
    }
  }

  const calculateOpeningTotal = () => {
    const total = Object.values(collection).reduce((sum, row) => {
      if (row.opening_balance) {
        sum += parseNumber(row.opening_balance.toString());
      }
      return sum;
    }, 0);

    return numberWithCommas(total);
  }

  return (
      <tr>
        <th></th>

        {monthsForYear.map((month, index) => {
          const isNewYear = index !== 0 && index % 12 === 0;
          const isFirstMonth = index === 0;
          const monthFormat = month.format('MMM YYYY');
          let yearTotal = '';

          if (isNewYear) {
            yearTotal = calculateYearTotal(monthsForYear.slice(index - 12, index), index / 12);
          }

          let monthTotal = '';

          if (monthTotalsEvaluator) {
            monthTotal = monthTotalsEvaluator(month);
          } else {
            monthTotal = numberWithCommas(cells.filter((c) => {
                return moment(c.month).isSame(month);
            })
                .map((c) => parseNumber(c.value.toString()))
                .reduce((sum, current) => sum + current, 0));
          }

          return (
              <React.Fragment key={monthFormat}>
                  {hasOpeningBalance && isFirstMonth && <th className='cell'>
                      <div className='cell-no-padding'>
                          <div className='cell-width' />
                          {cellHint && <span className="cell-hint-text">{cellHint}</span>}
                          <input type="text" readOnly={true}
                                 className="no-highlight total-input"
                                 value={calculateOpeningTotal()}
                                 style={{width: "100%", textAlign: 'right'}}/>
                      </div>
                    </th>
                  }
                  {isNewYear && timePeriodTotals === TimePeriodTotals.Both && <th className='yearDivider cell'>
                      <div className='cell-no-padding'>
                          <div className='cell-width' />
                          {cellHint && <span className="cell-hint-text">{cellHint}</span>}
                          <input type="text" readOnly={true}
                                 className="no-highlight total-input"
                                 value={yearTotal}
                                 style={{width: "100%", textAlign: 'right'}}/>
                      </div>
                  </th>}
                  <th className={isNewYear ? 'yearDivider cell' : 'cell'}>
                      <div className='cell-no-padding'>
                          <div className='cell-width' />
                          {cellHint && <span className="cell-hint-text">{cellHint}</span>}
                          <input type="text" readOnly={true}
                                 className="no-highlight total-input"
                                 value={monthTotal}
                                 style={{width: "100%", textAlign: 'right'}}/>
                      </div>
                  </th>
              </React.Fragment>
          )
        })}
        {timePeriodTotals === TimePeriodTotals.Both && <th className='yearDivider cell'>
            <div className='cell-no-padding'>
                <div className='cell-width' />
                {cellHint && <span className="cell-hint-text">{cellHint}</span>}
                <input type="text" readOnly={true}
                       className="no-highlight total-input"
                       value={calculateYearTotal(monthsForYear.slice(monthsForYear.length - 12, monthsForYear.length), monthsForYear.length / 12)}
                       style={{width: "100%", textAlign: 'right'}}/>
            </div>
        </th>}
      </tr>
  );
}