import * as Http from "../../utils/http";

export enum OtherCreditorsScope {
  All = 'all',
  Opening = 'opening',
  New = 'new',
}

interface FetchCreditorsParams {
  scope: OtherCreditorsScope
}

export async function fetchCreditors({ scope }: FetchCreditorsParams) {
  const [json,] = await Http.get(`/other_creditors.json?scope=${scope}`);
  return json;
}

export async function updateCreditorMonth(id: string, newValue: string, field: string = 'value') {
  const [json,] = await Http.patch(`/creditor_monthly_amounts/${id}`, {
    creditor_monthly_amount: { [field]: newValue }
  });
  return json;
}
