import { uuid } from '../../utils/uuid'

export interface OpeningBalanceSheetData {
  [key: string]: any
  fixedAssets: FixedAsset[]
  currentAssets: CurrentAssets
  currentLiabilities: CurrentLiabilities
  additionalFields: AdditionalFields
}

export interface FixedAssetCategory {
  id: string
  name: string
}

export interface FixedAsset {
  [key: string]: any
  id: string
  category: FixedAssetCategory
  originalValue: string
  bookValue: string
}

export const emptyFixedAsset = () => {
  return {
    id: uuid('_new_record'),
    category: {} as FixedAssetCategory,
    originalValue: '',
    bookValue: ''
  }
}

export interface CurrentAssets {
  [key: string]: any
  stock: string
  tradeDebtorsTotal: string
  tradeDebtorsMonth1: string
  tradeDebtorsMonth2: string
  tradeDebtorsMonth3: string
  tradeDebtorsMonth4: string
  cashInBank: string
  debtors: Debtor[]
  autoBalanceCorrection: string
  total: string
}

export interface Debtor {
  [key: string]: any
  id: string
  name: string
  amount: string
}

export const emptyDebtor = (): Debtor => {
  return {
    id: uuid('_new_record'),
    name: '',
    amount: ''
  }
}

export interface CurrentLiabilities {
  [key: string]: any
  overdraft: string
  invoiceDiscounting: string
  tradeCreditorsTotal: string
  tradeCreditorsMonth1: string
  tradeCreditorsMonth2: string
  tradeCreditorsMonth3: string
  tradeCreditorsMonth4: string
  payeNi: string
  vat: string
  corporationTax: string
  creditors: Creditor[]
  existingLoans: ExistingLoan[]
  autoBalanceCorrection: string
  total: string
}

export interface Creditor {
  [key: string]: any
  id: string
  name: string
  amount: string
}

export const emptyCreditor = (): Creditor => {
  return {
    id: uuid('_new_record'),
    name: '',
    amount: ''
  }
}

export interface ExistingLoan {
  [key: string]: any
  id: string
  name: string
  amountOutstanding: string
  monthlyFixedPayment: string
  noOfMonthsRemaining: string
}

export const emptyExistingLoan = () => {
  return {
    id: uuid('_new_record'),
    name: '',
    amountOutstanding: '',
    monthlyFixedPayment: '',
    noOfMonthsRemaining: ''
  }
}

export interface AdditionalFields {
  [key: string]: any
  netAssets: string
  shareCapital: string
  reserves: string
}
