import * as React from "react";

import SalesDataTable from './sales/SalesDataTable'
import MarginDataTable from './sales/MarginDataTable'
import GrossProfitDataTable from './sales/GrossProfitDataTable'

export default function SalesDataTables() {
  const onTableChange = () => {
    document.dispatchEvent(new CustomEvent('data_table:change'))
  }

  return (
    <React.Fragment>
      <div id='sales-data-table' className="mb-4">
        <SalesDataTable onTableChange={onTableChange} />
      </div>
      <div id='margin-data-table' className="mb-4">
        <MarginDataTable onTableChange={onTableChange} />
      </div>
      <div id='gross-profit-data-table' className="mb-4">
        <GrossProfitDataTable />
      </div>
    </React.Fragment>
  )
}
