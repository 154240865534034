import * as React from "react";
import { parseNumber, numberWithCommas } from "../../../misc/number_formatter";

type Props = {
  className?: string,
  cellHint?: string
  value: any,
  onChange: (newValue: string) => void,
  onBlur: (newValue: string) => void,
  onCopyForward: (newValue: string) => void,
  readOnly?: boolean
  disableCopyForward?: boolean,
  [otherProps: string]: any
}

export default function Cell({ className, cellHint, value, onChange, onBlur, onCopyForward, readOnly, disableCopyForward, ...otherProps }: Props) {

  const [inputIsFocused, setInputIsFocused] = React.useState(false);
  const [hoveringOverActions, setHoveringOverActions] = React.useState(false);
  const [dirty, setDirty] = React.useState(false);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
    setDirty(true);
  }

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setInputIsFocused(true);
    e.target.select();
  }

  const handleOnBlur = () => {
    setInputIsFocused(false);

    let newValue = value;

    if (cellHint === "£" || cellHint === "%") {
      const decimalPlaces = cellHint === "£" ? 0 : 2;
      newValue = numberWithCommas(parseNumber(value), decimalPlaces);
      onChange(newValue);
    }

    if (!hoveringOverActions && !readOnly && dirty) {
      onBlur(newValue);
      setDirty(false);
    }
  }

  // Make actions disappear when clicking action link
  const handleCopyForward = () => {
    setHoveringOverActions(false);
    onCopyForward(value);
    setInputIsFocused(false);
  }

  return (
    <td className={(className ? className : '') + ' cell'}>
      <div className='cell-no-padding'>
        <div className='cell-width' />

        {cellHint && <span className="cell-hint-text">{cellHint}</span>}

        <input
          type="text"
          value={value}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          style={{width: "100%", textAlign: 'right'}}
          className="cell-value-input"
          readOnly={readOnly}
          {...otherProps}
        />

        {(inputIsFocused || hoveringOverActions) && !readOnly && !disableCopyForward &&
          <div
            className="cell-actions"
            onMouseEnter={() => setHoveringOverActions(true)}
            onMouseLeave={() => setHoveringOverActions(false)}
          >
            <span className="btn btn-sm btn-cell-action-copy-forward" onClick={handleCopyForward}>
              Copy Forward
              <i className="fas fa-arrow-right ml-1"></i>
            </span>
          </div>
        }
      </div>
    </td>
  )
}