import * as React from "react";
import { Moment } from "moment";
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import DataTable, {TimePeriodTotals, RowData} from "../../components/DataTable";
import * as ProductsApi from '../../modules/products/api';
import * as SalesApi from '../../modules/sales/api';
import * as DataTablesApi from "../../modules/datatables/api";

const noProductsToDisplay = (
  <tr>
    <th className='text-center py-4 text-wrap'>
      <p>No products to display.</p>

      <p className='mb-0'>
        <a href='/products/new'>Click here</a> to create a new product.
      </p>
    </th>
  </tr>
);

const noMonthsToDisplay = () => (
  <tr>
    <th className='text-center py-4 text-wrap'>
      <p>No sales to display.</p>
    </th>
  </tr>
);

const tableHeader = (
  <div>
    <i className="fas fa-cubes fa-fw text-muted mr-2"></i>
    Sales
  </div>
)

const rowHeader = ({ link, name, otherProps }: RowData) => (
  <>
    {link && <a className='d-inline-block text-wrap' href={link} style={{width: '80%'}}>{name}</a>}
    {(link == undefined || link === '') && name}
    {otherProps.show_vat_info && (
      <OverlayTrigger
        placement='top'
        overlay={
          <Tooltip id='revenue-info'>
            {otherProps.revenue_includes_vat ? 'VAT included in amount' : 'Amount excludes VAT'}
          </Tooltip>
        }
      >
        <i className='fas fa-info-circle float-right'></i>
      </OverlayTrigger>
    )}
  </>
)

type Props= {
  onTableChange?: () => void,
}

export default function SalesDataTable({
  onTableChange
}: Props) {

  const initializeTable = async () => {
    return await ProductsApi.fetchProducts('sales');
  }

  const commitCellUpdate = async (newValue: string, rowId: string, cellId: string) => {
    await SalesApi.updateSale(cellId, newValue);

    if (onTableChange)
      onTableChange();
  }

  const onCellCopyForward = async (newValue: string, rowId: string, cellId: string, month: Moment) => {
    await DataTablesApi.copyForward(rowId, 'Product', 'sales', newValue, month.toString());

    if (onTableChange)
      onTableChange();
  }

  return <DataTable
    initializeTable={initializeTable}
    columnsHeader='Product/Service'
    tableHeader={tableHeader}
    commitCellUpdate={commitCellUpdate}
    noRowsToDisplay={noProductsToDisplay}
    noMonthsToDisplay={noMonthsToDisplay}
    onCellCopyForward={onCellCopyForward}
    timePeriodTotals={TimePeriodTotals.Both}
    cellHint='£'
    rowHeaderContent={rowHeader}
  />
}
