import * as React from 'react'
import { CurrencyInputWithLabel, CurrencyInput } from '../../components/CurrencyInput'
import { isZero, currencyToNumber, numberToCurrency  } from '../../../misc/currency'
import CreditorsTable from './CreditorsTable'
import ExistingLoansTable from './ExistingLoansTable'
import { CurrentLiabilities, OpeningBalanceSheetData } from '../../modules/opening-balance-sheet/types'

interface Error {
  [key: string]: string | undefined
}

function CurrentLiabilitiesCard(
  props: {openingBalanceSheet: OpeningBalanceSheetData, setParent: Function}
) {
  const [currentLiabilities, setCurrentLiabilities] = React.useState<CurrentLiabilities>(props.openingBalanceSheet.currentLiabilities)
  const [error, setError] = React.useState<Error>({})

  const validateTradeCreditorsTotal = (liabilities: CurrentLiabilities) => {
    const total = [
      currencyToNumber(liabilities.tradeCreditorsMonth1),
      currencyToNumber(liabilities.tradeCreditorsMonth2),
      currencyToNumber(liabilities.tradeCreditorsMonth3),
      currencyToNumber(liabilities.tradeCreditorsMonth4),
    ].reduce((sum, month) => sum + month)

    if (total !== currencyToNumber(liabilities.tradeCreditorsTotal)) {
      setError({ ...error, tradeCreditorsTotal: 'Paid month splits must equal the total amount.' })
    } else {
      setError({ ...error, tradeCreditorsTotal: undefined })
    }
  }

  const updateField = (field: string) => (value: any) => {
    const clone = {...currentLiabilities}
    clone[field] = value
    setCurrentLiabilities(clone)
    props.setParent(clone)

    validateTradeCreditorsTotal(clone)
  }

  return (
    <div className='card mb-4'>
      <div className='card-header'>
        Current Liabilities
      </div>
      <div className='card-body'>
        <CurrencyInputWithLabel
          label='Overdraft'
          value={currentLiabilities.overdraft}
          setHook={updateField('overdraft')}
          disabled={!isZero(props.openingBalanceSheet.currentAssets.cashInBank)}
        />
        {/*
        Pending implementation of invoice discounting
        <CurrencyInputWithLabel
          label='Invoice Discounting'
          value={currentLiabilities.invoiceDiscounting}
          setHook={updateField('invoiceDiscounting')}
        />*/}
        <div className='form-row form-group'>
          <div className='col-2 col-form-label' style={{marginTop: '30px'}}>
            <label>Trade Creditors</label>
          </div>
          <div className='col-2'>
            <label><strong>Total</strong></label>
            <CurrencyInput
              value={currentLiabilities.tradeCreditorsTotal}
              setHook={updateField('tradeCreditorsTotal')}
            />
          </div>
          <div className='col-2'>
            <label><strong>Paid month 1</strong></label>
            <CurrencyInput
              value={currentLiabilities.tradeCreditorsMonth1}
              setHook={updateField('tradeCreditorsMonth1')}
              className={error.tradeCreditorsTotal && 'is-invalid'}
            />
          </div>
          <div className='col-2'>
            <label><strong>Paid month 2</strong></label>
            <CurrencyInput
              value={currentLiabilities.tradeCreditorsMonth2}
              setHook={updateField('tradeCreditorsMonth2')}
              className={error.tradeCreditorsTotal && 'is-invalid'}
            />
          </div>
          <div className='col-2'>
            <label><strong>Paid month 3</strong></label>
            <CurrencyInput
              value={currentLiabilities.tradeCreditorsMonth3}
              setHook={updateField('tradeCreditorsMonth3')}
              className={error.tradeCreditorsTotal && 'is-invalid'}
            />
          </div>
          <div className='col-2'>
            <label><strong>Paid month 4</strong></label>
            <CurrencyInput
              value={currentLiabilities.tradeCreditorsMonth4}
              setHook={updateField('tradeCreditorsMonth4')}
              className={error.tradeCreditorsTotal && 'is-invalid'}
            />
          </div>
          {error.tradeCreditorsTotal && (
            <div className='col offset-4'>
              <div className="small text-danger">{error.tradeCreditorsTotal}</div>
            </div>
          )}
        </div>
        <CurrencyInputWithLabel
          label='PAYE/NI/Pensions'
          value={currentLiabilities.payeNi}
          setHook={updateField('payeNi')}
        />
        {props.openingBalanceSheet.showVat &&
          <CurrencyInputWithLabel
            label='VAT'
            value={currentLiabilities.vat}
            setHook={updateField('vat')}
          />
        }
        {props.openingBalanceSheet.showCorporationTax &&
          <CurrencyInputWithLabel
            label='Corporation Tax'
            value={currentLiabilities.corporationTax}
            setHook={updateField('corporationTax')}
          />
        }
        <div className='row'>
          <div className='col-2 col-form-label'>
            Other Creditors
          </div>
          <div className='col-9'>
            <CreditorsTable
              creditors={currentLiabilities.creditors}
              setParent={updateField('creditors')}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-2 col-form-label'>
            Existing Loans
          </div>
          <div className='col-9'>
            <ExistingLoansTable
              existingLoans={currentLiabilities.existingLoans}
              setParent={updateField('existingLoans')}
            />
          </div>
        </div>
        <CurrencyInputWithLabel
          label='Auto-Balance Correction'
          value={currentLiabilities.autoBalanceCorrection}
          setHook={updateField('autoBalanceCorrection')}
          disabled={true}
        />
        <strong>
          <CurrencyInputWithLabel
            label='Total'
            value={currentLiabilities.total}
            setHook={updateField('total')}
            disabled={true}
          />
        </strong>
      </div>
    </div>
  )
}

export default CurrentLiabilitiesCard
