import * as React from 'react'
import { CurrencyInput } from '../../components/CurrencyInput'
import { Creditor } from '../../modules/opening-balance-sheet/types'

function CreditorRow(
  props: {creditor: Creditor, handleRemoveRow: any, setParent: Function}
) {
  const [creditor, setCreditor] = React.useState(props.creditor)

  const updateField = (field: string) => (e: any) => {
    const value = e.target.value
    updateFieldValue(field)(value)
  }

  const updateFieldValue = (field: string) => (value: any) => {
    const clone = {...creditor}
    clone[field] = value
    setCreditor(clone)
    props.setParent(field, value)
  }

  return (
    <tr id={creditor.id}>
      <td className='pl-0'>
        <input
          value={creditor.name}
          onChange={updateField('name')}
          className='form-control string'
          placeholder='Name'
        />
      </td>
      <td>
        <CurrencyInput
          value={creditor.amount}
          setHook={updateFieldValue('amount')}
          placeholder='Amount'
        />
      </td>
      <td className='align-middle pr-0'>
        <a
          className='btn btn-sm btn-light border py-2'
          onClick={props.handleRemoveRow}
        >
          <i className='fas fa-fw fa-trash'></i>
        </a>
      </td>
    </tr>
  )
}

export default CreditorRow
