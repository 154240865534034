import * as React from "react";
import { Moment } from "moment";
import DataTable, {TimePeriodTotals} from "../components/DataTable";
import * as StocksApi from '../modules/stocks/api';
import * as DataTablesApi from "../modules/datatables/api";

// technically impossible
const noRowsToDisplay = <React.Fragment></React.Fragment>;

const noMonthsToDisplay = () => (
    <tr>
      <th className='text-center py-4 text-wrap'>
        <p>No stocks to display.</p>
      </th>
    </tr>
);

const tableHeader = (
    <div>
      <i className="fas fa-cubes fa-fw text-muted mr-2"></i>
        Stock value at month end
    </div>
)

export default function StocksDataTable() {
  const initializeTable = async () => {
    return await StocksApi.fetchStocks();
  }

  const commitCellUpdate = async (newValue: string, rowId: string, cellId: string) => {
    await StocksApi.updateStock(cellId, newValue);
  }

  const onCellCopyForward = async (
    newValue: string,
    rowId: string,
    cellId: string,
    month: Moment,
    resourceType?: string,
    resourceId?: string,
    toMonth?: Moment
  ) => {
    if (resourceId !== undefined && toMonth !== undefined)
      await DataTablesApi.copyForward(resourceId, 'Company', 'stocks', newValue, month.toString(), 'value', toMonth.toString());
  }

  return <DataTable
      initializeTable={initializeTable}
      columnsHeader=''
      tableHeader={tableHeader}
      commitCellUpdate={commitCellUpdate}
      noRowsToDisplay={noRowsToDisplay}
      noMonthsToDisplay={noMonthsToDisplay}
      onCellCopyForward={onCellCopyForward}
      cellHint='£'
      timePeriodTotals={TimePeriodTotals.None}
      hasOpeningBalance
      headerMonthFormat='MMM'
  />
}


