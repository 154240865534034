import * as Http from "../../utils/http";
import { SalaryMonth } from "./types";

export enum SalariesScope {
  All = 'all',
  Employees = 'employees',
  CostOfSales = 'cost_of_sales',
}

interface FetchSalariesParams {
  scope: SalariesScope
}

export async function fetchSalaries({ scope }: FetchSalariesParams) {
  const [json,] = await Http.get(`/salaries.json?scope=${scope}`);
  return json;
}

export async function updateSalaryMonth(id: string, newValue: string) {
  const [{ success },] = await Http.patch(`/salary_months/${id}`, { salary_month: { value: newValue } });

  if (success === true)
    return Promise.resolve();
  else
    return Promise.reject();
}