import * as React from 'react';
import moment from 'moment';
import {TimePeriodTotals} from "../DataTable";

type Props = {
  type: string,
  monthsForYear: moment.Moment[],
  timePeriodTotals: TimePeriodTotals,
  hasOpeningBalance?: boolean,
  monthFormat?: string
}

export default function HeaderRow({ type, monthsForYear, timePeriodTotals, hasOpeningBalance, monthFormat }: Props) {
  return (
    <tr>
      <th style={{width: '200px'}}>{type}</th>

      {monthsForYear.map((month, index) => {
        const isNewYear = index !== 0 && index % 12 === 0;
        const isEndOfYear = index % 12 === 11;
        const formattedMonth = month.format(monthFormat || 'MMM YYYY');
        const isFirstMonth = index === 0;

        return (
            <React.Fragment key={month.toString()}>
              {
                hasOpeningBalance && isFirstMonth && (
                  <th style={{textAlign: 'center'}}>
                    Opening Balance
                  </th>
                 )
              }
              <th className={isNewYear ? 'yearDivider' : ''} style={{textAlign: 'center'}}>
                {formattedMonth}
              </th>
              {(timePeriodTotals === TimePeriodTotals.Both || timePeriodTotals === TimePeriodTotals.Yearly) && isEndOfYear && <th className='yearDivider'></th>}
            </React.Fragment>
        )
      })}
    </tr>
  );
}