import {
  OpeningBalanceSheetData,
  CurrentAssets,
  CurrentLiabilities,
  AdditionalFields,
  FixedAsset,
  Debtor,
  Creditor,
  ExistingLoan,
} from "./types";
import { isZero } from '../../../misc/currency'

export function apiToState(data: any): OpeningBalanceSheetData {
  const obs: OpeningBalanceSheetData = {} as OpeningBalanceSheetData

  obs.fixedAssets = data.fixed_assets.map((fixed_asset: any): FixedAsset => {
    return {
      id: fixed_asset.id,
      category: fixed_asset.category,
      originalValue: fixed_asset.original_value,
      bookValue: fixed_asset.book_value,
    }
  })
  obs.currentAssets = {
    stock:                 data.stock,
    tradeDebtorsTotal:     data.trade_debtors_total,
    tradeDebtorsMonth1:    data.trade_debtors_month1,
    tradeDebtorsMonth2:    data.trade_debtors_month2,
    tradeDebtorsMonth3:    data.trade_debtors_month3,
    tradeDebtorsMonth4:    data.trade_debtors_month4,
    cashInBank:            data.cash_in_bank,
    autoBalanceCorrection: data.assets_correction,
    total:                 data.assets_total,
    debtors:               data.debtors.map((debtor: any): Debtor => {
                             return {
                               id: debtor.id,
                               name: debtor.name,
                               amount: debtor.value,
                             }
                           })
  } as CurrentAssets
  obs.currentLiabilities = {
    overdraft:             data.overdraft,
    invoiceDiscounting:    data.invoice_discounting,
    tradeCreditorsTotal:   data.trade_creditors_total,
    tradeCreditorsMonth1:  data.trade_creditors_month1,
    tradeCreditorsMonth2:  data.trade_creditors_month2,
    tradeCreditorsMonth3:  data.trade_creditors_month3,
    tradeCreditorsMonth4:  data.trade_creditors_month4,
    payeNi:                data.paye_ni,
    vat:                   data.vat,
    corporationTax:        data.corporation_tax,
    autoBalanceCorrection: data.liabilities_correction,
    total:                 data.liabilities_total,
    creditors:             data.creditors.map((creditor: any): Creditor => {
                             return {
                               id: creditor.id,
                               name: creditor.name,
                               amount: creditor.value,
                             }
                           }),
    existingLoans:         data.existing_loans.map((loan: any): ExistingLoan => {
                             return {
                               id: loan.id,
                               name: loan.name,
                               amountOutstanding: loan.amount_outstanding,
                               monthlyFixedPayment: loan.monthly_fixed_payment,
                               noOfMonthsRemaining: loan.no_of_months_remaining,
                             }
                           })
  } as CurrentLiabilities
  obs.additionalFields = {
    netAssets:    data.net_assets,
    shareCapital: data.share_capital,
    reserves:     data.reserves,
  } as AdditionalFields

  obs.showVat = data.show_vat
  obs.showCorporationTax = data.show_corporation_tax

  return obs;
}

export function stateToApi(obs: OpeningBalanceSheetData): object {
  const data = {
    stock: obs.currentAssets.stock,
    trade_debtors_total: obs.currentAssets.tradeDebtorsTotal,
    trade_debtors_month1: obs.currentAssets.tradeDebtorsMonth1,
    trade_debtors_month2: obs.currentAssets.tradeDebtorsMonth2,
    trade_debtors_month3: obs.currentAssets.tradeDebtorsMonth3,
    trade_debtors_month4: obs.currentAssets.tradeDebtorsMonth4,
    ...((!isZero(obs.currentAssets.cashInBank) || isZero(obs.currentAssets.overdraft)) && { cash_in_bank: obs.currentAssets.cashInBank }),

    ...((!isZero(obs.currentLiabilities.overdraft) || isZero(obs.currentAssets.cashInBank)) && { overdraft: obs.currentLiabilities.overdraft }),
    invoice_discounting: obs.currentLiabilities.invoiceDiscounting,
    trade_creditors_total: obs.currentLiabilities.tradeCreditorsTotal,
    trade_creditors_month1: obs.currentLiabilities.tradeCreditorsMonth1,
    trade_creditors_month2: obs.currentLiabilities.tradeCreditorsMonth2,
    trade_creditors_month3: obs.currentLiabilities.tradeCreditorsMonth3,
    trade_creditors_month4: obs.currentLiabilities.tradeCreditorsMonth4,
    paye_ni: obs.currentLiabilities.payeNi,
    vat: obs.currentLiabilities.vat,
    corporation_tax: obs.currentLiabilities.corporationTax,

    share_capital: obs.additionalFields.shareCapital,
    reserves: obs.additionalFields.reserves,
  } as any

  data.fixed_assets_attributes = obs.fixedAssets.map((fixedAsset: FixedAsset) => {
    return {
      ...(!fixedAsset.id?.startsWith('_new_record') && { id: fixedAsset.id }),
      category_id: fixedAsset.category.id,
      original_value: fixedAsset.originalValue,
      book_value: fixedAsset.bookValue,
      _destroy: fixedAsset._destroy,
    }
  })
  data.debtors_attributes = obs.currentAssets.debtors.map((debtor: Debtor) => {
    return {
      ...(!debtor.id?.startsWith('_new_record') && { id: debtor.id }),
      name: debtor.name,
      value: debtor.amount,
      _destroy: debtor._destroy,
    }
  })
  data.creditors_attributes = obs.currentLiabilities.creditors.map((creditor: Creditor) => {
    return {
      ...(!creditor.id?.startsWith('_new_record') && { id: creditor.id }),
      name: creditor.name,
      value: creditor.amount,
      _destroy: creditor._destroy,
    }
  })
  data.existing_loans_attributes = obs.currentLiabilities.existingLoans.map((loan: ExistingLoan) => {
    return {
      ...(!loan.id?.startsWith('_new_record') && { id: loan.id }),
      name: loan.name,
      amount_outstanding: loan.amountOutstanding,
      monthly_fixed_payment: loan.monthlyFixedPayment,
      no_of_months_remaining: loan.noOfMonthsRemaining,
      _destroy: loan._destroy,
    }
  })

  return data;
}
