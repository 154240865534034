import moment from 'moment'

// Return array of the months between two dates.
export const monthRange = (startMonth: moment.Moment, endMonth: moment.Moment) => {
  if (!startMonth.isValid() || !endMonth.isValid()) {
    return [];
  }

  const month = startMonth.clone();
  const output = [];
  while (endMonth > month || month.format('M') === endMonth.format('M')) {
    output.push(month.clone());
    month.add(1, 'month');
  }
  return output;
}
